import React from "react";

function ProcessArea({ processDatas, className }) {
  return (
    <section className={`process-area pb-115 pt-125 ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="section-title pb-40">
              <h2 className="text-white text-center"> NFT Business Process </h2>
            </div>
          </div>
        </div>

        <div className="row">
          {processDatas &&
            processDatas.length > 0 &&
            processDatas.map((item) => (
              <div key={Math.random()} className="col-xl-3 col-lg-3 col-md-6">
                <div
                  className="single_business_process wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <img
                    src={require(`../../assets/img/${item.icon}`).default}
                    alt="nift"
                  />
                  <h2 className="text-capitalize">{item.title}</h2>
                  <p className="line-clump-4">{item.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default ProcessArea;
