import React from "react";

function MainContent() {
  return (
    <section className="connect-wallet-area pt-220 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="connect-wallet-title">
              <h2>Connect your wallet</h2>
              <p>
                Image, Video, Audio, or 3D Model. File types supported: JPG,
                PNG, GIF, <br />
                SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max size: 100 MB
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 mb-30">
            <div className="connect-wallet-items">
              <ul>
                <li>
                  <a href="#">
                    <img
                      src={require(`../../../assets/img/wallet01.png`).default}
                      alt="nift"
                    />
                    Coin base wallet
                  </a>
                </li>

                <li>
                  <a href="#">
                    <img
                      src={require(`../../../assets/img/wallet02.png`).default}
                      alt="nift"
                    />
                    Coin base wallet
                  </a>
                </li>

                <li>
                  <a href="#">
                    <img
                      src={require(`../../../assets/img/wallet03.png`).default}
                      alt="nift"
                    />
                    Coin base wallet
                  </a>
                </li>

                <li>
                  <a href="#">
                    <img
                      src={require(`../../../assets/img/wallet04.png`).default}
                      alt="nift"
                    />
                    Coin base wallet
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="wallet-right-img">
              <img
                src={
                  require(`../../../assets/img/11_Connect your wallet/Image.png`)
                    .default
                }
                alt="nift"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainContent;
