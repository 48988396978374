import React from "react";
import img from "../../../assets/img/22_NFT_Blogs/Image.png";
import imgThree from "../../../assets/img/23_NFT Blogs-Details/Image-3.png";
import imgFour from "../../../assets/img/23_NFT Blogs-Details/Image-4.png";

function MainContent() {
  return (
    <>
      <div
        className="blog-details-img text-center wow fadeIn"
        data-wow-delay=".3s"
      >
        <img src={imgFour} alt="nift" />
      </div>

      <section className="blog-details-main-wrapper  pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 ">
              <div className="blog-details-content">
                <div className="blog-meta">
                  <h2>
                    Alethea introduces The <br />
                    Revenants iNFTs
                  </h2>
                  <ul>
                    <li>
                      <span>October 14, 2021</span>
                    </li>
                    <li>
                      <a href="@">By Hamish Barnes</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="blog-single-contents">
                <p>
                  The Revenants is a first-of-its-kind collection, depicting
                  historical and cultural icons reborn. From Nikola Tesla to
                  Napoleon to Cleopatra, the world’s greatest minds and stories
                  are now being brought to life as Intelligent NFTs. iNFTs are a
                  powerful new medium that not only seeks to advance the
                  utilities of NFTs but also unlock new economic, creative, and
                  social possibilities. The First iNFT was auctioned
                  successfully through Sotheby’s on June 10th, 2021 for nearly
                  half a million USD. <br /> <br /> You can find the Revenants
                  by visiting alethea.ai and entering the Intelligent Metaverse
                  known as Noah’s Ark. You can broadcast a custom video message
                  that the iNFT, Robin Hood recites, or ask Snow White what the
                  meaning of love is. On Noah’s Ark, all iNFTs have varying
                  levels of intelligence that allow them to interact.
                </p>
                <img className="mt-30 mb-50" src={imgThree} alt="nift" />
                <p>
                  Step 1: Choose a format Pitch is your chance to show clients
                  the designer behind the work. Here are a few different formats
                  you can choose from to achieve this:
                  <br /> <br /> Present yourself — Film a short introductory
                  video sharing your work history, experience, education, and
                  more. Create a sizzle reel — Showcase your work through a
                  video montage to highlight the depth and breadth of your
                  skills. Share your creative process — Talk future clients
                  through how you approach the design process and what it looks
                  like to work with you.While these are the most obvious formats
                  for your Pitch, we encourage you to get creative with it! Let
                  your imagination run wild and tell your story, your way.
                </p>
              </div>

              <div className="blog-details-pagination mt-50">
                <ul className="clearfix pro-detais-pagination-ul">
                  <li className="f-left">
                    <a href="#">
                      <i className="fa-solid fa-angles-left"></i> Previous Post
                      <h3>
                        Alethea introduces The Reve <br />
                        nants iNFTs
                      </h3>
                    </a>
                  </li>

                  <li className="f-right text-end">
                    <a href="#">
                      Previous Post <i className="fa-solid fa-angles-right"></i>
                      <h3>
                        Alethea introduces The Reve <br />
                        nants iNFTs
                      </h3>
                    </a>
                  </li>
                </ul>

                <div className="row">
                  <div className="blog-bottom-title text-center">
                    <h3> Other similar posts </h3>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 mb-30">
                    <div className="blog-wrapper">
                      <div className="blog-img">
                        <img src={img} alt="nift" />
                      </div>
                      <div className="blog-body">
                        <ul className="clearfix">
                          <li className="f-left w-50">
                            <a className="btn" href="#">
                              Spotlight
                            </a>
                          </li>
                          <li className="f-right w-50 text-end">
                            <span>October 15, 2021</span>
                          </li>
                        </ul>
                        <h3>The Rusty Robots</h3>
                        <p>
                          Intriguing new NFTs from the likes of Joey Khamis,
                          OG:Crystals, Alethea, Yonat Vaks, NFT-V Miami, and Roe
                          Ethridge.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 mb-30">
                    <div className="blog-wrapper">
                      <div className="blog-img">
                        <img src={img} alt="nift" />
                      </div>
                      <div className="blog-body">
                        <ul className="clearfix">
                          <li className="f-left w-50">
                            <a className="btn" href="#">
                              Spotlight
                            </a>
                          </li>
                          <li className="f-right w-50 text-end">
                            <span>October 15, 2021</span>
                          </li>
                        </ul>
                        <h3>The Rusty Robots</h3>
                        <p>
                          Intriguing new NFTs from the likes of Joey Khamis,
                          OG:Crystals, Alethea, Yonat Vaks, NFT-V Miami, and Roe
                          Ethridge.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 mb-30">
                    <div className="blog-wrapper">
                      <div className="blog-img">
                        <img src={img} alt="nift" />
                      </div>
                      <div className="blog-body">
                        <ul className="clearfix">
                          <li className="f-left w-50">
                            <a className="btn" href="#">
                              Spotlight
                            </a>
                          </li>
                          <li className="f-right w-50 text-end">
                            <span>October 15, 2021</span>
                          </li>
                        </ul>
                        <h3>The Rusty Robots</h3>
                        <p>
                          Intriguing new NFTs from the likes of Joey Khamis,
                          OG:Crystals, Alethea, Yonat Vaks, NFT-V Miami, and Roe
                          Ethridge.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainContent;
