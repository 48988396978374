import React, { useEffect } from "react";
import useToggle from "../../../hooks/useToggle";
import StickyMenu from "../../../lib/StickyMenu";
import Navigations from "../../Helpers/Navigations";

function HeaderOne({ action, className }) {
  const [toggle, setToggle] = useToggle(false);
  useEffect(() => {
    StickyMenu();
  });
  return (
    <>
      <header className={`header-area sticky ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-3">
              <div className="row">
                <div className="col-xl-3 col-lg-3">
                  <div className="d-flex justify-content-between align-content-center">
                    <div className="header-logo">
                      <a href="/">
                        <img
                          src={require(`../../../assets/img/Logo.png`).default}
                          alt="nift"
                        />
                      </a>
                    </div>
                    <div className="drawer-button d-block d-lg-none">
                      <svg
                        onClick={(e) => action(e)}
                        style={{ fill: "currentColor", color: "#fff" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 d-none d-xl-block">
                  <div className="header-search-box">
                    <form action="#">
                      <i className="pe-7s-search"></i>
                      <input
                        placeholder="Search items, collections, accounts"
                        type="search"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-7 col-lg-9  d-none d-lg-block">
              <div className="row">
                <div className="col-xl-7 col-lg-8">
                  <div className="mobile-menu">
                    <nav id="mobile-menu">
                      <div className="header-menu">
                        <Navigations />
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-4 d-none d-lg-block">
                  <div className="header-right-btn">
                    <div className="header-main-btn">
                      <a href="#" className="btn">
                        Create
                      </a>
                    </div>
                    <div className="header-icon">
                      <ul>
                        <li>
                          <span
                            onClick={setToggle.toggle}
                            className="text-white cursor-pointer"
                          >
                            <i className="fa-solid fa-user"></i>
                          </span>
                          {toggle && (
                            <div
                              onClick={setToggle.toggle}
                              className="profile-dropdown-away"
                            ></div>
                          )}
                          <ul
                            id="use_deshboad_menu"
                            className={`profile-dropdown-wrap ${
                              toggle ? "active" : ""
                            }`}
                          >
                            <li>
                              <a href="#">
                                <h3>Jonathon Doe</h3>
                              </a>
                            </li>
                            <li>
                              <div className="user-balance clearfix">
                                <div className="user-b-img-left f-left">
                                  <img
                                    src={
                                      require(`../../../assets/img/balance.png`)
                                        .default
                                    }
                                    alt="nift"
                                  />
                                </div>
                                <div className="user-b-wrap-right f-right">
                                  <span>Balance</span>
                                  <h4>133.25 ETH</h4>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a href="/my-profile">
                                <img
                                  src={
                                    require(`../../../assets/img/user2.png`)
                                      .default
                                  }
                                  alt="nift"
                                />
                                My Profile
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src={
                                    require(`../../../assets/img/dollar-sign2.png`)
                                      .default
                                  }
                                  alt="nift"
                                />
                                Manage Funds
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src={
                                    require(`../../../assets/img/columns.png`)
                                      .default
                                  }
                                  alt="nift"
                                />
                                My Collections
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src={
                                    require(`../../../assets/img/heart.png`)
                                      .default
                                  }
                                  alt="nift"
                                />
                                Favorites
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src={
                                    require(`../../../assets/img/settings.png`)
                                      .default
                                  }
                                  alt="nift"
                                />
                                Settings
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src={
                                    require(`../../../assets/img/log-out.png`)
                                      .default
                                  }
                                  alt="nift"
                                />
                                Log Out
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-ml">
                          <a href="#">
                            <i className="fa-brands fa-gitlab"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderOne;
