import React from "react";
import StyleOne from "../Cards/StyleOne";
import SelectBox from "../Helpers/SelectBox";

function ExplorSection({ exploredata }) {
  const filterPhotography = [
    " Photography ",
    " Photography 1",
    " Photography 2",
    " Photography 3",
  ];
  const filterPrice = [
    " Low price ",
    "  Low price 1",
    "  Low price 2",
    "  Low price 3",
  ];
  const priceRange = [
    "  Price Range",
    "  Price Range 1",
    "  Price Range 2",
    "  Price Range 3",
  ];
  const creators = [
    "  Creators",
    "  Creators 1",
    "  Creators 2",
    "  Creators 3",
  ];
  const recentlyAdded = [
    "  Recently Added",
    "  Recently Added 1",
    "  Recently Added 2",
    "  Recently Added 3",
  ];
  return (
    <section className="explore-area pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="section-title pb-40">
              <h2> Explore </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <form action="#">
              <ul className="explore-option-wrappper">
                <li className="list-inline-custom">
                  <SelectBox
                    datas={filterPhotography}
                    className="explore-section-select-box"
                  />
                </li>
                <li className="list-inline-custom">
                  <SelectBox
                    datas={filterPrice}
                    className="explore-section-select-box"
                  />
                </li>
                <li className="list-inline-custom">
                  <SelectBox
                    datas={priceRange}
                    className="explore-section-select-box"
                  />
                </li>
                <li className="list-inline-custom list-inline-margin">
                  <SelectBox
                    datas={creators}
                    className="explore-section-select-box"
                  />
                </li>
                <li className="list-inline-custom m-0">
                  <SelectBox
                    datas={recentlyAdded}
                    className="explore-section-select-box"
                  />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="row mb-30 mt-20 artist-carousel">
          {exploredata &&
            exploredata.length > 0 &&
            exploredata
              .slice(0, 8)
              .reverse()
              .map((item, index) => (
                <div
                  key={Math.random()}
                  className="col-xl-3 col-lg-4 mb-30 col-md-6"
                >
                  <StyleOne datas={item} evenly dataIndex={index + 1} />
                </div>
              ))}
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="artist-btn text-center">
              <a className="btn" href="#">
                View More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExplorSection;
