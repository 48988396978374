import React from "react";

function MainContent() {
  return (
    <>
      <section className="top-nftp-area pb-120 pt-220">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="nftp-title">
                <h2>Top NFTs</h2>
                <p>
                  The top NFTs on OpenSea, ranked by volume, floor price and
                  other statistics.
                </p>
              </div>
            </div>
            <div className="col-xl-8 pt-65">
              <div className="nftp-right-select">
                <form action="#">
                  <select name="" id="">
                    <option value=""> Last 7 days </option>
                    <option value=""> Last 7 days </option>
                    <option value=""> Last 7 days </option>
                    <option value=""> Last 7 days </option>
                    <option value=""> Last 7 days </option>
                    <option value=""> Last 7 days </option>
                    <option value=""> Last 7 days </option>
                    <option value=""> Last 7 days </option>
                    <option value=""> Last 7 days </option>
                  </select>

                  <select name="" id="">
                    <option value="">All categories </option>
                    <option value=""> All categories </option>
                    <option value=""> All categories </option>
                    <option value=""> All categories </option>
                    <option value=""> All categories </option>
                    <option value=""> All categories </option>
                    <option value=""> All categories </option>
                    <option value=""> All categories </option>
                    <option value=""> All categories </option>
                  </select>

                  <select name="" id="">
                    <option value=""> All chains </option>
                    <option value=""> All chains </option>
                    <option value=""> All chains </option>
                    <option value=""> All chains </option>
                    <option value=""> All chains </option>
                    <option value=""> All chains </option>
                    <option value=""> All chains </option>
                    <option value=""> All chains </option>
                    <option value=""> All chains </option>
                  </select>
                </form>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="main-table-area pt-20">
                <table className="mb-20" style={{ width: "100%" }}>
                  <tr className="tr-th-custom">
                    <th> Collection </th>
                    <th> Volume </th>
                    <th> 24h % </th>
                    <th> 7d % </th>
                    <th> Floor Price </th>
                    <th> Owners </th>
                    <th> Assets </th>
                  </tr>
                </table>

                <table className="tale-border-custom">
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                  <tr className="tr-td-custom-css">
                    <td className="td-1">
                      <span> 1 </span>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        CryptoPunks
                      </a>
                    </td>
                    <td className="td-2">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      <h5>133,871.43</h5>
                    </td>
                    <td className="td-3"> -48.31% </td>
                    <td className="td-4"> +1396.90% </td>
                    <td className="td-5">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />{" "}
                      133
                    </td>
                    <td className="td-6">3.2K</td>
                    <td className="td-7">10.0K</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div className="row text-right">
            <div className="col-xl-12">
              <div className="top-nftp-bottom-btn ">
                <button type="button">
                  <i className="fa-solid fa-angle-left"></i> <span>01-50</span>
                </button>
                <button type="button">
                  <span>51-201</span>
                  <i className="fa-solid fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainContent;
