import React from "react";
import useToggle from "../../../hooks/useToggle";
import GoToTop from "../../Helpers/GoToTop";
import Drawer from "../../Mobile/Drawer";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import MainContent from "./MainContent";

function ConnectWallet() {
  const [drawer, setDrawer] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={setDrawer.toggle} />
      <HeaderOne className="shop-page-header" action={setDrawer.toggle} />
      <MainContent />
      <FooterOne />
      <GoToTop />
    </>
  );
}

export default ConnectWallet;
