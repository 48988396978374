import React from "react";

function MainContent() {
  return (
    <section className="shop-filder-area pt-170 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-3">
            <div className="shop-left-main-filter">
              <ul>
                <li className=" filter-fst-wrap clearfix">
                  <div className="filder-left f-left w-50">
                    <a href="#">
                      <i className="fa-solid fa-filter"></i> <span>Filter</span>
                    </a>
                  </div>
                  <div className="filder-arrow-right f-right text-right w-50">
                    <a href="#">
                      <i className="fa-solid fa-arrow-left"></i>
                    </a>
                  </div>
                </li>

                <li className="filter2-wrap price-field">
                  <div className="filter-top-arrow-main-wrap"></div>
                  <ul className="clearfix statur-title">
                    <li className="f-left w-50">
                      <a href="#"> Event Types </a>
                    </li>
                    <li className="f-right w-50 text-right startu-icon-right">
                      <a href="#">
                        <i className="fa-solid fa-chevron-up"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="filter-btn-main-wrap clearfix">
                    <div className="filter-btn-left f-left w-50">
                      <a className="btn  active-btn single-btn-design" href="#">
                        Listings
                      </a>
                    </div>

                    <div className="filter-btn-right f-left w-50">
                      <a className="btn  f-default-btn" href="#">
                        Sales
                      </a>
                    </div>
                  </div>
                  <div className="filter-btn-main-wrap clearfix">
                    <div className="filter-btn-left f-left w-50">
                      <a className="btn  active-btn single-btn-design" href="#">
                        Bids
                      </a>
                    </div>

                    <div className="filter-btn-right f-left w-50">
                      <a className="btn  f-default-btn" href="#">
                        Transfers
                      </a>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="collection-main-wrapper">
                    <div className="collection-header clearfix bg-white">
                      <ul>
                        <li className="f-left">
                          <a href="#">
                            <h3>Collections</h3>
                          </a>
                        </li>
                        <li className="f-right">
                          <a href="#">
                            <i className="fa-solid fa-angle-up"></i>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="collection-body">
                      <form action="#">
                        <i>
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </i>
                        <input type="search" placeholder="Search" />
                      </form>
                      <ul>
                        <li>
                          <a href="#">
                            <img
                              src={
                                require(`../../../assets/img/21_Stats-Activity/Image-3.png`)
                                  .default
                              }
                              alt="nift"
                            />
                            <span>Leslie Alexander</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src={
                                require(`../../../assets/img/21_Stats-Activity/Image-4.png`)
                                  .default
                              }
                              alt="nift"
                            />
                            <span>Leslie Alexander</span>
                          </a>
                        </li>
                      </ul>

                      <ul className="mt-50">
                        <li>
                          <a href="#">
                            <img
                              src={
                                require(`../../../assets/img/21_Stats-Activity/Image-5.png`)
                                  .default
                              }
                              alt="nift"
                            />
                            <span>Leslie Alexander</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src={
                                require(`../../../assets/img/21_Stats-Activity/Image-6.png`)
                                  .default
                              }
                              alt="nift"
                            />
                            <span>Leslie Alexander</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="collection-main-wrapper">
                    <div className="collection-header clearfix bg-white">
                      <ul>
                        <li className="f-left">
                          <a href="#">
                            <h3>Chains</h3>
                          </a>
                        </li>
                        <li className="f-right">
                          <a href="#">
                            <i className="fa-solid fa-angle-up"></i>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="collection-body">
                      <ul>
                        <li>
                          <a href="#">
                            <img
                              src={
                                require(`../../../assets/img/21_Stats-Activity/Image-3.png`)
                                  .default
                              }
                              alt="nift"
                            />
                            <span>Leslie Alexander</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src={
                                require(`../../../assets/img/21_Stats-Activity/Image-4.png`)
                                  .default
                              }
                              alt="nift"
                            />
                            <span>Leslie Alexander</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src={
                                require(`../../../assets/img/21_Stats-Activity/Image-5.png`)
                                  .default
                              }
                              alt="nift"
                            />
                            <span>Leslie Alexander</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-9">
            <div className="status-activity-area">
              <table className="activity-table-tr1">
                <tr>
                  <th className="th01"> Item </th>
                  <th className="th02"> Price </th>
                  <th className="th03"> Quantity </th>
                  <th className="th04"> From </th>
                  <th className="th05"> To </th>
                  <th className="th06"> Time </th>
                </tr>
              </table>

              <table
                className="activity-table-tr mt-25"
                style={{ width: "100%" }}
              >
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require(`../../../assets/img/tag.png`).default}
                      alt="nift"
                    />
                    <span>
                      <a className="cryptopuks" href="#">
                        CryptoPunks
                      </a>
                    </span>
                  </td>
                  <td className="tdd2">
                    <img
                      className="activity-item-img"
                      src={
                        require(`../../../assets/img/20_Stats-Ranking/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <strong>Botties</strong>
                    <span>Top Dog #1234</span>
                  </td>
                  <td className="tdd4">
                    <img
                      src={
                        require(`../../../assets/img/ethereum 2.png`).default
                      }
                      alt="nift"
                    />
                    133,87
                  </td>
                  <td> 01 </td>
                  <td> 39690 </td>
                  <td className="tdd4"> ......... </td>
                  <td className="tdd4"> 12s ago </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainContent;
