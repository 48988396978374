import React from "react";

function PlaceABid({ action, value }) {
  return (
    <>
      <section
        id="wrapperModal"
        style={{ display: value ? "flex" : "none" }}
        className="modal-area modal modal-area2 product-details-popup"
      >
        <div
          onClick={action}
          className={`single-profile-away ${value ? "active" : ""}`}
        ></div>
        <div className="modal-single-wrap">
          <div className="closePopup" onClick={action}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="modal-title">
            <h3> Place a bid </h3>
            <p> Pou are about to place a bid for dddd by furkanmia </p>
            <form action="#">
              <div className="row">
                <div className="shop-detail-popup-wrap">
                  <h4>Your bid</h4>
                  <div className="shop-popup-left">
                    <button type="button">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      ETH
                    </button>
                  </div>
                  <div className="shop-popup-middle">
                    <input type="text" placeholder="110.566" />
                  </div>
                  <div className="shop-popup-right">
                    <button type="submit">$12,255,123.00</button>
                  </div>
                </div>

                <div className="shop-popup-body">
                  <input id="checkbox" type="checkbox" />
                  <label htmlFor="checkbox">
                    By checking this box, I agree to OpenSea's Terms of Service
                  </label>
                </div>
              </div>
            </form>

            <div className=" popup-bottom-title popup2-bottom-btn text-center">
              <a className="btn" href="#">
                Place a bid
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlaceABid;
