import React from "react";

function UserDetails() {
  return (
    <>
      <section className="detail-profile-top-bg mt-100"></section>
      <section className="detail-profile-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-2 col-lg-2">
              <div className="details-left">
                <a href="#">
                  <i className="fa-solid fa-globe"></i>
                </a>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="detail-profile-wrap">
                <img
                  className="profile-picture"
                  src={
                    require(`../../../assets/img/24_MyProfile/Profile_Image.png`)
                      .default
                  }
                  alt="nift"
                />
                <h2>Jonathon Doe</h2>
                <h5>
                  Created by <a href="#"> Jonathon Doe</a>
                </h5>
                <ul>
                  <li>
                    <h3>7.5K</h3>
                    <span>Items</span>
                  </li>

                  <li>
                    <h3> 3.5K </h3>
                    <span>Owners</span>
                  </li>

                  <li>
                    <h3>
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      3.5K
                    </h3>
                    <span>Floor Price</span>
                  </li>

                  <li>
                    <h3>
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      43.5K
                    </h3>
                    <span>Volume Trade</span>
                  </li>
                </ul>
                <p>
                  Pavel Sokov is a renowned portrait painter. When he was 24
                  years old, Sokov was approached by Time to paint the cover of
                  the Time's.... <a href="#">Read More</a>
                </p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3">
              <div className="detail-right-bar">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa-solid fa-arrow-up-from-bracket"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa-solid fa-gear"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserDetails;
