import React from "react";
import SelectBox from "../Helpers/SelectBox";

function TopCollectionSection({ collectionUser }) {
  const dayFilter = [
    "Last 1 Day",
    "Last 2 Day",
    "Last 3 Day",
    "Last 4 Day",
    "Last 5 Day",
  ];
  // const [valueDayFilter, setDayFilterValue] = useState(dayFilter[0]);
  // const handlerDayFilter = (value) => {
  //   setDayFilterValue(value);
  // };
  return (
    <section className="top-collection-area pb-60">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="top-collection-title">
              <h2> Top collection </h2>
            </div>
          </div>
          <div className="col-xl-6 col-xl-6 col-md-12">
            <div className="top-collection-form text-end">
              <form action="#">
                <SelectBox className="day-filter" datas={dayFilter} />
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          {collectionUser &&
            collectionUser.length > 0 &&
            collectionUser.slice(0, 16).map((item, index) => (
              <div key={Math.random()} className="col-xl-3 col-lg-4">
                <div className="top-collect-wrapper">
                  <ul>
                    <li>
                      <span>{index + 1 > 9 ? index + 1 : `0${index + 1}`}</span>
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../assets/img/home-01/${item.user_image}`)
                            .default
                        }
                        alt="nift"
                      />
                    </li>
                    <li className="collect-lst-list">
                      <a href="#">
                        <h3 className="text-capitalize">{item.username}</h3>
                      </a>
                      <h4>{item.price}</h4>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default TopCollectionSection;
