import React from "react";

function MainContent({ children, modalAction }) {
  return (
    <>
      <section className="product-details-area pt-200 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="product-single-details-left">
                <div className="product-detail-left-img">
                  <div className="product-ber">
                    <div className="product-details-feature">
                      <span> Art </span>
                      <img
                        src={
                          require(`../../../assets/img/Hover Play.png`).default
                        }
                        alt="nift"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="product-details-right">
                <div className="product-details-right-text">
                  <h3>The Rusty Robots</h3>
                  <h4>
                    Not for sale · 20 editions ·
                    <a href="#">Highest bid 10 wETH</a>
                  </h4>
                  <p>
                    We’re excited you’re interested in an NFT created by
                    Twitter. As a recipient of the Twitter NFT, either through
                    an initial transfer from Twitter or a subsequent transfer or
                    purchase,..
                  </p>
                  <a className="product-details-a" href="#">
                    Read More
                  </a>
                </div>
                <ul className="clearfix detail-page-top-menu">
                  <li className="f-left w-50 details-page-left-li">
                    <h3>Creator</h3>
                    <div className="creator-img">
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/05_Product-Details/Image-4.png`)
                              .default
                          }
                          alt="nift"
                        />
                        <h4>Rustery</h4>
                      </a>
                    </div>
                  </li>

                  <li className="f-right w-50 details-page-right-li">
                    <h3>Collection</h3>
                    <div className="creator-img">
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/img/05_Product-Details/Image-3.png`)
                              .default
                          }
                          alt="nift"
                        />
                        <h4>The 140 Collection</h4>
                      </a>
                    </div>
                  </li>
                </ul>

                <ul className="detail-page-menu">
                  <li>
                    <a href="#"> Owners </a>
                  </li>
                  <li>
                    <a href="#"> Bids </a>
                  </li>
                  <li>
                    <a href="#"> Details </a>
                  </li>
                  <li>
                    <a className="detail-page-menu-history" href="#">
                      History
                    </a>
                  </li>
                </ul>

                <div className="detail-right-user-profile">
                  <ul>
                    <li>
                      <div className="details-left-user-img">
                        <img
                          className="details-right-img1"
                          src={
                            require(`../../../assets/img/05_Product-Details/Image-1.png`)
                              .default
                          }
                          alt="nift"
                        />
                        <img
                          className="details-right-img2"
                          src={
                            require(`../../../assets/img/Verify.png`).default
                          }
                          alt="nift"
                        />
                      </div>

                      <div className="details-right-user-name">
                        <a href="#">
                          <h3>Bid 10 wETH</h3>
                        </a>
                        <p>by domiriganji 11/3/2021, 10:57 AM</p>
                      </div>
                    </li>
                    <li>
                      <div className="details-left-user-img">
                        <img
                          className="details-right-img1"
                          src={
                            require(`../../../assets/img/05_Product-Details/Image.png`)
                              .default
                          }
                          alt="nift"
                        />
                        <img
                          className="details-right-img2"
                          src={
                            require(`../../../assets/img/Verify2.png`).default
                          }
                          alt="nift"
                        />
                      </div>

                      <div className="details-right-user-name">
                        <a href="#">
                          <h3>Bid 10 wETH</h3>
                        </a>
                        <p>by domiriganji 11/3/2021, 10:57 AM</p>
                      </div>
                    </li>
                    <li>
                      <div className="details-left-user-img">
                        <img
                          className="details-right-img1"
                          src={
                            require(`../../../assets/img/05_Product-Details/Image-1.png`)
                              .default
                          }
                          alt="nift"
                        />
                        <img
                          className="details-right-img2"
                          src={
                            require(`../../../assets/img/Verify.png`).default
                          }
                          alt="nift"
                        />
                      </div>

                      <div className="details-right-user-name">
                        <a href="#">
                          <h3>Bid 10 wETH</h3>
                        </a>
                        <p>by domiriganji 11/3/2021, 10:57 AM</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="detail-profile-right-bottom">
                  <ul>
                    <li>
                      <img
                        src={
                          require(`../../../assets/img/05_Product-Details/Image-2.png`)
                            .default
                        }
                        alt="nift"
                      />

                      <div className="detail-profile-right-f-text">
                        <h3>
                          Highest bid by <a href="#">Richard Alpert</a>
                        </h3>
                        <h3>
                          <a href="#">1.1 WETH</a> $4,925 for 1 edition
                        </h3>
                      </div>
                    </li>

                    <li>
                      <a
                        onClick={(e) => modalAction(e)}
                        id="openPopup"
                        className="btn details-profile-right-btn1"
                        href="#"
                      >
                        Place a bid
                      </a>
                      <a
                        onClick={(e) => modalAction(e)}
                        id="openPopup2"
                        className="btn details-profile-right-btn2"
                        href="#"
                      >
                        Purchase Now
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {children && children}
    </>
  );
}

export default MainContent;
