import React from "react";
import ReviewCardStyleOne from "../Cards/ReviewCardStyleOne";
import SliderCom from "../Helpers/SliderCom";

function TestimonialSection({ reviews, className, reviewCardStyle }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className={`testimonial-area pt-130 pb-120 ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="section-title pb-40">
              <h2 className="text-white text-center"> NFT Business Process </h2>
            </div>
          </div>
        </div>

        <div className="row testemonial-active">
          <SliderCom settings={settings}>
            {reviews &&
              reviews.length > 0 &&
              reviews.map((item) => (
                <div key={Math.random()} className="col-xl-3 col-lg-4 col-md-6">
                  <ReviewCardStyleOne
                    datas={item}
                    className={reviewCardStyle || ""}
                  />
                </div>
              ))}
          </SliderCom>
        </div>
      </div>
    </section>
  );
}

export default TestimonialSection;
