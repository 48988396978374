import React from "react";
import { blogs } from "../../../data/blog.json";
import useToggle from "../../../hooks/useToggle";
import GoToTop from "../../Helpers/GoToTop";
import Drawer from "../../Mobile/Drawer";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import MainContent from "./MainContent";

function ConnectWallet() {
  const [drawer, setDrawer] = useToggle(false);
  const filterContent = [
    {
      id: 1,
      name: "Guide",
    },
    {
      id: 2,
      name: "Guest post",
    },
    {
      id: 3,
      name: "Spotlights",
    },
    {
      id: 4,
      name: "Safety & Security",
    },
    {
      id: 5,
      name: "Announcements",
    },
  ];

  return (
    <>
      <Drawer drawer={drawer} action={setDrawer.toggle} />
      <HeaderOne className="shop-page-header" action={setDrawer.toggle} />
      <MainContent blogs={blogs} blogFilter={filterContent} />
      <FooterOne />
      <GoToTop />
    </>
  );
}

export default ConnectWallet;
