import React, { useEffect } from "react";
import BackToTop from "../../lib/BackToTop";

function GoToTop({ className }) {
  useEffect(() => {
    BackToTop("#scrollUp");
  });
  const handler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <span onClick={handler} className={className || ""} id="scrollUp">
      <i className="fa-solid fa-arrow-up"></i>
    </span>
  );
}

export default GoToTop;
