import React from "react";
import { collectionUser } from "../../data/collectionUsersTwo.json";
import { process } from "../../data/processDark.json";
import { products } from "../../data/productHomeTwo.json";
import { reviews } from "../../data/reviews.json";
import useToggle from "../../hooks/useToggle";
import GoToTop from "../Helpers/GoToTop";
import LearnSection from "../HomeOne/LearnSection";
import LiveAuctions from "../HomeOne/LiveAuctions";
import ProcessArea from "../HomeOne/ProcessArea";
import TestimonialSection from "../HomeOne/TestimonialSection";
import DigitalProductSection from "../HomeTwo/DigitalProductSection";
import TopSelectionSection from "../HomeTwo/TopSelectionSection";
import Drawer from "../Mobile/Drawer";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderThree from "../Partials/Headers/HeaderThree";
import Hero from "./Hero";

function HomeDark() {
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={setDrawer.toggle} />
      <HeaderThree action={setDrawer.toggle} />
      <Hero />
      <DigitalProductSection
        products={products}
        icon="white"
        className="pt-0"
      />

      <TopSelectionSection
        collectionUser={collectionUser}
        className="pt-0 pb-0"
      />
      <ProcessArea className="home2-process-area" processDatas={process} />
      <LiveAuctions
        datas={products}
        cardStyle={2}
        className="live-auction-area pt-0 pb-0"
        btnStyle="auctions-btn"
      />
      <TestimonialSection
        reviews={reviews}
        className="home2-testimonial-area pb-120 pt-130 testimonial-area"
      />
      <LearnSection className="home2-learn-area" btnStyle="home2-sec-btn" />
      <FooterOne className="footer2-area" />
      <GoToTop />
    </>
  );
}

export default HomeDark;
