import React from "react";
import avatar from "../../assets/img/home-01/avatar.png";

function LearnSection({ className, btnStyle = "hero-sec-btn" }) {
  return (
    <section className={`learn-area pb-120 pt-110 ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="hero-left-content learn-left-content">
              <h2> Learn and Earn Free ETH with NFT </h2>
              <p> A creative nft busi ness agency that lead & inspire </p>
              <div className="hero-btn">
                <a href="#" className="btn">
                  Explore
                </a>
                <a href="#" className={`btn hero-sec-btn  ${btnStyle || ""}`}>
                  Create
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="row">
              <div className="col-xl-6 col-lg-6 mb-30">
                <div className="hero-right-area learn-right-content">
                  <div className="hero-right-img">
                    <img
                      src={require(`../../assets/img/home-01/10.jpg`).default}
                      alt="nift"
                    />
                  </div>
                  <div className="single-hero-meta">
                    <img src={avatar} alt="nift" />
                    <div className="hero-meta-text">
                      <a href="#">
                        <h4> Create Item </h4>
                      </a>
                      <span>$180,583</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 ">
                <div className="hero-right-area learn-right-content">
                  <div className="hero-right-img">
                    <img
                      src={require(`../../assets/img/home-01/11.png`).default}
                      alt="nift"
                    />
                  </div>
                  <div className="single-hero-meta">
                    <img src={avatar} alt="nift" />
                    <div className="hero-meta-text">
                      <a href="#">
                        <h4> Explore More </h4>
                      </a>
                      <span>$180,583</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LearnSection;
