import React, { useState } from "react";

function MainContent({ blogs, blogFilter }) {
  const [tab, setTab] = useState(blogFilter[0].id);
  const tabHandler = (value) => {
    if (value) {
      setTab(value);
    }
  };
  return (
    <section className="blog-area pt-150 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="blog-title wow fadeIn" data-wow-delay=".3s">
              <h3>NFT Blogs</h3>
              <p>
                The top NFTs on OpenSea, ranked by volume, floor price and other
                statistics.
              </p>
            </div>
          </div>
        </div>

        <div className="row wow fadeInUp" data-wow-delay=".3s">
          <div className="col-xl-12 col-lg-12">
            <div className="blog-menu">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {blogFilter &&
                  blogFilter.length > 0 &&
                  blogFilter.map((filItem) => (
                    <li
                      key={Math.random()}
                      className="nav-item"
                      role="presentation"
                    >
                      <button
                        className={`nav-link ${
                          tab === filItem.id ? "active" : ""
                        }`}
                        onClick={() => tabHandler(filItem.id)}
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#guides"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        {filItem.name}
                      </button>
                    </li>
                  ))}
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="guides"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    {blogs &&
                      blogs.length > 0 &&
                      blogs.map((blogItem) => (
                        <div
                          key={Math.random()}
                          className="col-xl-3 col-lg-3 mb-30 col-md-6"
                        >
                          <div className="blog-wrapper">
                            <div className="blog-img">
                              <img
                                src={
                                  require(`../../../assets/img/22_NFT_Blogs/${blogItem.thumbnail}`)
                                    .default
                                }
                                alt="nift"
                              />
                            </div>
                            <div className="blog-body">
                              <ul className="clearfix">
                                <li className="f-left w-50">
                                  <a className="btn" href="#">
                                    {blogItem.category}
                                  </a>
                                </li>
                                <li className="f-right w-50 text-end">
                                  <span>October 15, 2021</span>
                                </li>
                              </ul>
                              <h3 className="line-clump-1 text-capitalize">
                                {blogItem.title}
                              </h3>
                              <p className="line-clump-3">
                                {blogItem.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="row">
                    <div className="blog-paginaton text-end">
                      <ul>
                        <li>
                          <a className="blog-p-active" href="#">
                            1
                          </a>
                        </li>
                        <li>
                          <a href="#"> 2 </a>
                        </li>
                        <li>
                          <a href="#"> 3 </a>
                        </li>
                        <li>
                          <a className="blog-next" href="#">
                            Next <i className="fa-solid fa-angle-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainContent;
