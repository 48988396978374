import React, { useState } from "react";
import defaultImg from "../../../assets/img/12_Create-Fixed_Price/Bg.png";

function MainContent({ children, modalAction }) {
  const [img, setImage] = useState(defaultImg);
  const changeImg = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const sectionFilter = [
    {
      id: 1,
      name: "Fixed Price",
      icon: "dollar-sign1.png",
    },
    {
      id: 2,
      name: "Timed Action",
      icon: "list.png",
    },
    {
      id: 3,
      name: "Fixed Price",
      icon: "list.png",
    },
  ];
  const [tab, setTab] = useState(sectionFilter[0].id);
  const tabHandler = (v) => setTab(v);
  return (
    <>
      <section className="single-profile-area pt-220 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-12">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="single-profile-left">
                    <div className="single-profile-wrap">
                      <h2>Create single item</h2>
                      <p>
                        Image, Video, Audio, or 3D Model. File types supported:
                        JPG, PNG, GIF, <br />
                        SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max size: 100
                        MB
                      </p>
                      <form action="#">
                        <h4> Upload File </h4>
                        <div className="upload-img">
                          <img
                            src={
                              require(`../../../assets/img/upload.png`).default
                            }
                            alt="nift"
                          />
                          <input
                            onChange={changeImg}
                            className="upload-custom"
                            type="file"
                          />
                        </div>
                      </form>
                      <div className="user-upload-form">
                        <form action="#">
                          <div className="user-single-upload-item">
                            <label> Name </label>
                            <input placeholder="Item Name" type="text" />
                          </div>
                          <div className="user-single-upload-item">
                            <label> External Link </label>
                            <input
                              placeholder="https://www.youtube.com/watch?v=Oz9zw7-_vhM"
                              type="text"
                            />
                          </div>
                          <div className="user-single-uplod-text-area">
                            <label>Description</label>
                            <textarea
                              placeholder="Provide a detailed description of your item."
                              name=""
                              id=""
                            ></textarea>
                          </div>
                          <div className="user-block-chain">
                            <label> Blockchain </label>
                            <input placeholder="Ethereum" type="text" />
                            <img
                              src={
                                require(`../../../assets/img/image.png`).default
                              }
                              alt="nift"
                            />
                          </div>
                          <div className="single-profile-price-filter-wrap">
                            <ul
                              className="nav nav-pills first-tab-custom-css mb-3"
                              id="pills-tab"
                              role="tablist"
                            >
                              {sectionFilter &&
                                sectionFilter.length > 0 &&
                                sectionFilter.map((item) => (
                                  <li
                                    key={Math.random()}
                                    className="nav-item"
                                    role="presentation"
                                  >
                                    <button
                                      className={`nav-link ${
                                        tab === item.id ? "active" : ""
                                      }`}
                                      onClick={() => tabHandler(item.id)}
                                      id="pills-home-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-home"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-home"
                                      aria-selected="true"
                                    >
                                      <img
                                        src={
                                          require(`../../../assets/img/${item.icon}`)
                                            .default
                                        }
                                        alt="nift"
                                      />
                                      {item.name}
                                    </button>
                                  </li>
                                ))}
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                              <div
                                className={`tab-pane fade ${
                                  tab === 1 ? "show active" : ""
                                }`}
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                              >
                                <div className="price-input-box">
                                  <label>Price</label>
                                  <span>
                                    ETH
                                    <i className="fa-solid fa-angle-down"></i>
                                  </span>
                                  <input type="text" placeholder="100" />
                                </div>
                              </div>
                              <div
                                className={`tab-pane fade ${
                                  tab === 2 ? "show active" : ""
                                }`}
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                              >
                                <div className="price-input-box">
                                  <label> Starting Date </label>
                                  <span>
                                    ETH
                                    <i className="fa-solid fa-angle-down"></i>
                                  </span>
                                  <input type="text" placeholder="100" />
                                </div>

                                <div className="dubbol-price-box clearfix">
                                  <div className="price-input-box single-price-box-right f-left w-50 mt-10">
                                    <label> Expiration Date </label>
                                    <span>
                                      ETH
                                      <i className="fa-solid fa-angle-down"></i>
                                    </span>
                                    <input type="text" placeholder="100" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`tab-pane fade ${
                                tab === 3 ? "show active" : ""
                              }`}
                              id="pills-contact"
                              role="tabpanel"
                              aria-labelledby="pills-contact-tab"
                            >
                              ...
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="collect2-main-wrap">
                        <h3>Collection</h3>

                        <form action="#">
                          <i className="fa-solid fa-angle-down"></i>
                          <input type="text" placeholder="Select Collection" />
                        </form>
                      </div>

                      <div className="accordion-wrapper">
                        <ul className="accordon-wrap-ul">
                          <li>
                            <a href="#">
                              <img
                                src={
                                  require(`../../../assets/img/list.png`)
                                    .default
                                }
                                alt="nift"
                              />
                              Properties
                              <span>
                                <img
                                  src={
                                    require(`../../../assets/img/plus.png`)
                                      .default
                                  }
                                  className="plus-accordion"
                                  alt="nift"
                                />
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img
                                src={
                                  require(`../../../assets/img/star.png`)
                                    .default
                                }
                                alt="nift"
                              />
                              Levels
                              <span>
                                <img
                                  src={
                                    require(`../../../assets/img/plus.png`)
                                      .default
                                  }
                                  className="plus-accordion"
                                  alt="nift"
                                />
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img
                                src={
                                  require(`../../../assets/img/bar-chart-2.png`)
                                    .default
                                }
                                alt="nift"
                              />
                              Levels
                              <span>
                                <img
                                  src={
                                    require(`../../../assets/img/plus.png`)
                                      .default
                                  }
                                  className="plus-accordion"
                                  alt="nift"
                                />
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img
                                src={
                                  require(`../../../assets/img/unlock.png`)
                                    .default
                                }
                                alt="nift"
                              />
                              Unlockable Content
                              <span>
                                <img
                                  src={
                                    require(`../../../assets/img/plus.png`)
                                      .default
                                  }
                                  className="plus-accordion"
                                  alt="nift"
                                />
                              </span>
                            </a>
                          </li>
                        </ul>

                        <ul className="accordion-switch-ul">
                          <li>
                            <a href="#">
                              <img
                                src={
                                  require(`../../../assets/img/unlock2.png`)
                                    .default
                                }
                                alt="nift"
                              />
                              Unlockable Content
                              <div className="form-check form-switch form-main-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="flexSwitchCheckChecked"
                                  checked
                                />
                              </div>
                            </a>
                          </li>
                          <li>
                            <p>
                              Unlock content (access key, link to a file etc)
                            </p>
                          </li>
                          <li className="switch-li-pt">
                            <a href="#">
                              <img
                                src={
                                  require(`../../../assets/img/help-circle.png`)
                                    .default
                                }
                                alt="nift"
                              />
                              Explicit & Sensitive Content
                              <div className="form-check form-switch form-main-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="flexSwitchCheckChecked"
                                  checked
                                />
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="accor-main-wrp-bottom-btn">
                        <a className="btn" href="#">
                          Create
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12">
              <div className="single-profile-right">
                <div className="right-preview-box">
                  <h3>Preview</h3>
                  <div className="right-preview-img-box">
                    <img src={img} alt="nift" />
                    <ul className="clearfix">
                      <li className="f-left w-50">
                        <a href="#">
                          <span>20 ETH</span> <strong>0 editions</strong>
                        </a>
                      </li>
                      <li className="f-right w-50 text-end previe-bottom-btn">
                        <button
                          type="button"
                          id="openPopup"
                          className="btn"
                          onClick={modalAction}
                        >
                          Place a bid
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {children && children}
    </>
  );
}

export default MainContent;
