import React from "react";
import useToggle from "../../../hooks/useToggle";
import GoToTop from "../../Helpers/GoToTop";
import PlaceABid from "../../Helpers/Modals/PlaceABid";
import Drawer from "../../Mobile/Drawer";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import MainContent from "./MainContent";

function PlaceABidCom() {
  const [drawer, setDrawer] = useToggle(false);
  const [value, setValue] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={setDrawer.toggle} />
      <HeaderOne className="shop-page-header" action={setDrawer.toggle} />
      <MainContent modalAction={setValue.toggle}>
        <PlaceABid action={setValue.toggle} value={value} />
      </MainContent>
      <FooterOne />
      <GoToTop />
    </>
  );
}

export default PlaceABidCom;
