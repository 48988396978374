import React from "react";
import SliderCom from "../Helpers/SliderCom";

function Sponser() {
  const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="brand-area pt-115">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="brand-active text-center">
              <SliderCom settings={settings}>
                <div className="single-brand text-center">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/img/Client-img-2-H.png`).default
                      }
                      alt="nift"
                    />
                  </a>
                </div>
                <div className="single-brand text-center">
                  <a href="#">
                    <img
                      src={require(`../../assets/img/Client-img-4.png`).default}
                      alt="nift"
                    />
                  </a>
                </div>
                <div className="single-brand text-center">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/img/Client-img-5-H.png`).default
                      }
                      alt="nift"
                    />
                  </a>
                </div>
                <div className="single-brand text-center">
                  <a href="#">
                    <img
                      src={require(`../../assets/img/Client-img-4.png`).default}
                      alt="nift"
                    />
                  </a>
                </div>
                <div className="single-brand text-center">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/img/Client-img-2-H.png`).default
                      }
                      alt="nift"
                    />
                  </a>
                </div>
                <div className="single-brand text-center">
                  <a href="#">
                    <img
                      src={require(`../../assets/img/Client-img-4.png`).default}
                      alt="nift"
                    />
                  </a>
                </div>
              </SliderCom>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sponser;
