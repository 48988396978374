import React, { useState } from "react";
import useToggle from "../../../hooks/useToggle";
import "./style.css";

function SelectBox({ datas = [], className, action }) {
  const [item, setItem] = useState(datas[0]);
  // custom hook
  const [toggle, setToggle] = useToggle(false);
  const handler = (e, value) => {
    e.preventDefault();
    if (action) {
      action(value);
    }
    setItem(value);
    setToggle.toggle(value);
  };
  return (
    <>
      {datas.length > 0 && (
        <div className={`my-select-box ${className}`}>
          <button
            onClick={(e) => setToggle.toggle(e)}
            type="button"
            className="my-select-box-btn"
          >
            <span>{item}</span>
            <span>
              <i className="fas fa-angle-down"></i>
            </span>
          </button>
          <div className={`my-select-box-section ${toggle ? "open" : ""}`}>
            <ul className="list">
              {datas.map((value) => (
                <li
                  className={item === value ? "selected" : ""}
                  key={value + datas.length}
                  onClick={(e) => handler(e, value)}
                >
                  {value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectBox;
