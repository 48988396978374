import React, { useState } from "react";
import StyleOne from "../../Cards/StyleOne";
import FilterSection from "./FilterSection";

function MainSection({ products }) {
  const tabItem = [
    {
      id: 1,
      name: "Items",
    },
    {
      id: 2,
      name: "Activity",
    },
  ];
  const [tab, setTab] = useState(tabItem[0].id);
  const tabHandler = (value) => setTab(value);
  return (
    <section className="shop-filder-area my-profile2-service pt-60 pb-90">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <FilterSection />
          </div>

          <div className="col-xl-9 col-lg-8">
            <ul
              className="nav tab-active-text-css text-end"
              id="myTab"
              role="tablist"
            >
              {tabItem &&
                tabItem.length > 0 &&
                tabItem.map((item) => (
                  <li
                    key={Math.random()}
                    className="nav-item"
                    role="presentation"
                  >
                    <button
                      className={`nav-link ${item.id === tab ? "active" : ""}`}
                      onClick={() => tabHandler(item.id)}
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
            </ul>
            <div className="tab-content" id="myTabContent">
              {tabItem &&
                tabItem.length > 0 &&
                tabItem.map((item) => (
                  <div
                    key={Math.random()}
                    className={`tab-pane fade ${
                      item.id === tab ? "active show" : ""
                    }`}
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        <div className="detail-profile-filter-main-wrap details-profile-resp">
                          <div className="shop-result-text detail-profile-filter">
                            <form action="#">
                              <input placeholder="Search" type="search" />
                              <img
                                className="detail-profile-search"
                                src={
                                  require(`../../../assets/img/search2.png`)
                                    .default
                                }
                                alt="nift"
                              />
                            </form>

                            <form action="#">
                              <select name="" id="">
                                <option value="">Single items </option>
                                <option value="">Single items 1</option>
                                <option value="">Single items 2</option>
                                <option value="">Single items 3</option>
                                <option value="">Single items 4</option>
                                <option value="">Single items 5</option>
                              </select>
                            </form>

                            <form action="#">
                              <select name="" id="">
                                <option value="">Price: High to Low </option>
                                <option value="">Price: High to Lowy 1</option>
                                <option value="">Price: High to Low 2</option>
                                <option value="">Price: High to Low 3</option>
                                <option value="">Price: High to Low 4</option>
                                <option value="">Price: High to Low 5</option>
                              </select>
                            </form>

                            <ul>
                              <li>
                                <a className="filter-a-left" href="#">
                                  <img
                                    src={
                                      require(`../../../assets/img/grid.png`)
                                        .default
                                    }
                                    alt="nift"
                                  />
                                </a>
                              </li>
                              <li>
                                <a className="filter-a-right" href="#">
                                  <img
                                    src={
                                      require(`../../../assets/img/list-filter.png`)
                                        .default
                                    }
                                    alt="nift"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {products &&
                        products.length > 0 &&
                        products.slice(0, 12).map((productItem) => (
                          <div
                            key={Math.random()}
                            className="col-xl-4 col-lg-6 mb-30"
                          >
                            <StyleOne datas={productItem} />
                          </div>
                        ))}
                    </div>

                    <div className="row">
                      <div className="col-xl-12">
                        <div className="shop-button-wrap text-center profile-details-view-more">
                          <a className="btn" href="#">
                            View More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
