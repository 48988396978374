import React from "react";
import { collectionUser } from "../../data/collectionUsers.json";
import { explorer } from "../../data/exploreData.json";
import { liveAuctions } from "../../data/liveAuctions.json";
import { process } from "../../data/process.json";
import { reviews } from "../../data/reviews.json";
import useToggle from "../../hooks/useToggle";
import GoToTop from "../Helpers/GoToTop";
import Drawer from "../Mobile/Drawer";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderOne from "../Partials/Headers/HeaderOne";
import ExplorSection from "./ExplorSection";
import Hero from "./Hero";
import LearnSection from "./LearnSection";
import LiveAuctions from "./LiveAuctions";
import ProcessArea from "./ProcessArea";
import Sponser from "./Sponser";
import TestimonialSection from "./TestimonialSection";
import TopCollectionSection from "./TopCollectionSection";

function HomeOne() {
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={setDrawer.toggle} />
      <HeaderOne action={setDrawer.toggle} />
      <Hero />
      <LiveAuctions datas={liveAuctions} />
      <TopCollectionSection collectionUser={collectionUser} />
      <ProcessArea processDatas={process} />
      <ExplorSection exploredata={explorer} />
      <TestimonialSection reviews={reviews} />
      <Sponser />
      <LearnSection />
      <FooterOne />
      <GoToTop />
    </>
  );
}

export default HomeOne;
