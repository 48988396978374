import React from "react";
import MiniCardStyleOne from "../Cards/MiniCardStyleOne";
import SelectBox from "../Helpers/SelectBox";

function TopSelectionSection({ collectionUser, className }) {
  const dayFilter = [
    "Last 1 Day",
    "Last 2 Day",
    "Last 3 Day",
    "Last 4 Day",
    "Last 5 Day",
  ];
  return (
    <section
      className={`top-collectoin-area clearfix pb-100 ${className || ""}`}
    >
      <div className="container">
        <div className="row mb-50">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="top-collection-title">
              <h2> Top collection </h2>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="top-collection-form text-end">
              <form action="#">
                <SelectBox
                  className="day-filter day-filter-two"
                  datas={dayFilter}
                />
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          {collectionUser &&
            collectionUser.length > 0 &&
            collectionUser.slice(0, 10).map((item) => (
              <div key={Math.random()} className="col-xl-25 mb-30">
                <MiniCardStyleOne datas={item} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default TopSelectionSection;
