import React, { useEffect, useState } from "react";

function CountDown({ lastDate = "" }) {
  const [showDate, setDate] = useState(0);
  const [showHour, setHour] = useState(0);
  const [showSecound, setDateSecound] = useState(0);
  // count Down
  const provideDate = new Date(lastDate);
  // format date
  const year = provideDate.getFullYear();
  const month = provideDate.getMonth();
  //   console.log(month);
  const date = provideDate.getDate();
  //   console.log(date);
  const hours = provideDate.getHours();
  //   console.log(hours);
  const minutes = provideDate.getMinutes();
  //   console.log(minutes);
  const seconds = provideDate.getSeconds();
  //   console.log(seconds);

  // date calculation logic
  const _seconds = 1000;
  const _minutes = _seconds * 60;
  const _hours = _minutes * 60;
  const _date = _hours * 24;

  // interval function
  const startInterval = () => {
    const timer = setInterval(() => {
      const now = new Date();
      const distance =
        new Date(year, month, date, hours, minutes, seconds).getTime() -
        now.getTime();
      if (distance < 0) {
        clearInterval(timer);
        return;
      }
      setDate(Math.floor(distance / _date));
      setHour(Math.floor((distance % _date) / _hours));
      setDateSecound(Math.floor((distance % _minutes) / _seconds));
    }, 1000);
  };

  // effect
  useEffect(() => {
    if (lastDate !== "") {
      startInterval();
    }
  });
  return (
    <ul className="product-cowndown-border">
      <li>
        <h3 className="day">{showDate}</h3> <span>days</span>
      </li>
      <li>
        <h3 className="hours"> {showHour}</h3> <span>hrs</span>
      </li>
      <li>
        <h3 className="second"> {showSecound} </h3> <span>secs</span>
      </li>
    </ul>
  );
}

export default CountDown;
