import React from "react";
import { Link } from "react-router-dom";
import CountDown from "../Helpers/CountDown";

function StyleTwo({ datas, cardStyleClass }) {
  return (
    <div className={`single-product ${cardStyleClass || ""}`}>
      <div className="single-product-img">
        <img
          src={require(`../../assets/img/02_Home/${datas.thumbnail}`).default}
          alt="nift"
        />
        <span>{datas.category}</span>
      </div>

      <div
        className="product-time-coundown nft-count-down-mod"
        data-date="May 5, 2022 12:37:25"
      >
        <CountDown lastDate={datas.last_date} />
        <Link to="/product-details">
          <h2 className="text-capitalize line-clump-1" title={datas.title}>
            {datas.title}
          </h2>
        </Link>
        <span>{datas.username}</span>
        <div className="single-product-heart">
          <ul>
            <li className="f-left w-50">{datas.price}</li>
            <li className="f-right w-50 text-end">
              <a href="#">
                <i className="fa-solid fa-heart"></i>
                <span>{datas.review}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default StyleTwo;
