import React from "react";

function MiniCardStyleOne({ datas }) {
  return (
    <div className="top-collectoin-single-product">
      <div className="top-collect-img">
        <a href="#">
          <img
            className="top-collect-top-img"
            src={require(`../../assets/img/02_Home/${datas.thumbnail}`).default}
            alt="nift"
          />
        </a>

        <div className="top-collet-inner-img">
          <a href="#">
            <img
              src={
                require(`../../assets/img/02_Home/${datas.user_image}`).default
              }
              alt="nift"
            />
          </a>
          <a href="#">
            <h3>@{datas.username}</h3>
          </a>
          <span>{datas.price}</span>
        </div>
      </div>
    </div>
  );
}

export default MiniCardStyleOne;
