import React, { useRef } from "react";
import StyleOne from "../Cards/StyleOne";
import StyleTwo from "../Cards/StyleTwo";
import SliderCom from "../Helpers/SliderCom";

function LiveAuctions({
  datas = [],
  cardStyle = 1,
  className,
  btnStyle = "artist-btn",
}) {
  const liveAuctionsSlider = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  const handlePrev = () => liveAuctionsSlider.current.slickPrev();
  const handleNext = () => liveAuctionsSlider.current.slickNext();
  return (
    <section
      className={`artist-carousel-area pt-100 pb-130 ${className || ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="section-title pb-40">
              <h2> Live auctions </h2>
            </div>
          </div>
        </div>
        <div className="row artist-carousel-active">
          <strong
            onClick={handlePrev}
            className="priv_arrow slick-arrow"
            style={{ display: "block" }}
          >
            <i className="fa-solid fa-angle-left"></i>
          </strong>
          <SliderCom selector={liveAuctionsSlider} settings={settings}>
            {datas &&
              datas.length > 0 &&
              datas.slice(0, 8).map((item) => (
                <div key={Math.random()} className="pl-15 pr-15">
                  {cardStyle === 1 ? (
                    <StyleOne datas={item} />
                  ) : cardStyle === 2 ? (
                    <StyleTwo datas={item} />
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </SliderCom>
          <strong
            onClick={handleNext}
            className="next_arrow slick-arrow"
            style={{ display: "block" }}
          >
            <i className="fa-solid fa-angle-right"></i>
          </strong>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-3">
            <div className={`text-center ${btnStyle}`}>
              <a className="btn" href="#">
                View More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LiveAuctions;
