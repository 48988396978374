import React from "react";
import { Link } from "react-router-dom";

function Navigations() {
  return (
    <ul>
      <li>
        <Link to="#"> Home </Link>
        <ul>
          <li>
            <Link to="/"> Home 1 </Link>
          </li>
          <li>
            <Link to="/home-two"> Home 2 </Link>
          </li>
          <li>
            <Link to="/home-dark"> Home 3 </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="#"> Explore </Link>
        <ul>
          <li>
            <Link to="#">
              Stats
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <ul className="submenu">
              <li>
                <Link to="/status-activity">Status Activity</Link>
              </li>
              <li>
                <Link to="/status-ranking">Status Ranking</Link>
              </li>
              <li>
                <Link to="/my-profile"> My Profile </Link>
              </li>
              <li>
                <Link to="/collection-activity">Collecton Activity</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="#">
              Resources
              <i className="fa-solid fa-angle-right"></i>
            </Link>
            <ul className="submenu">
              <li>
                <Link to="/status-activity">Status Activity</Link>
              </li>
              <li>
                <Link to="/open-for-bids">Open For Bids</Link>
              </li>
              <li>
                <Link to="/place-a-bid">Place A Bid</Link>
              </li>
              <li>
                <Link to="/add-levels"> Add Levels </Link>
              </li>
              <li>
                <Link to="/add-stats"> Add Stats </Link>
              </li>
              <li>
                <Link to="/offer-settings">Offer Setting</Link>
              </li>
              <li>
                <Link to="/perchase-successfully">Perchase</Link>
              </li>
              <li>
                <Link to="/place-bid2">Place A Bid 2</Link>
              </li>
              <li>
                <Link to="/place-bid3">Place A Bid 3</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="#">
              Community
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <ul className="submenu">
              <li>
                <Link to="/my-profile"> My Profile </Link>
              </li>
              <li>
                <Link to="/collection-activity">Collectoin Activity</Link>
              </li>
              <li>
                <Link to="/connect-wallet">Connect Wallet</Link>
              </li>
              <li>
                <Link to="/profile-settings">Profile Setting</Link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <Link to="#"> Pages </Link>
        <ul>
          <li>
            <Link to="/detail-item"> Detail Item </Link>
          </li>

          <li>
            <Link to="/offer-settings">Offer Setting</Link>
          </li>
          <li>
            <Link to="/product-details">Product Details</Link>
          </li>

          <li>
            <Link to="/status-activity">Status Activity</Link>
          </li>
          <li>
            <Link to="/status-ranking">Status Ranking</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="#"> Blogs </Link>
        <ul>
          <li>
            <Link to="/blog"> Blog </Link>
          </li>
          <li>
            <Link to="/blog-details"> Blog Details </Link>
          </li>
        </ul>
      </li>
    </ul>
  );
}

export default Navigations;
