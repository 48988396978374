import React from "react";
import StyleTwo from "../Cards/StyleTwo";

function DigitalProductSection({
  products,
  className,
  icon = "",
  cardStyleClass,
  countDownStyle,
}) {
  return (
    <section
      className={`digital-product-area  pb-130 pt-190 ${className || ""}`}
    >
      <div className="container">
        <div className="row mb-45">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="digital-product-title">
              <h2>Digital Products</h2>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="digital-product-count">
              <span>30k Products</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-12">
            <div className="digital-product-filter-wrap">
              <div className="digital-p-filer-single">
                <ul>
                  <li>
                    <form className="digital-p-main-form" action="#">
                      {icon === "white" ? (
                        <img
                          src={
                            require(`../../assets/img/recent-added.png`).default
                          }
                          alt="nift"
                        />
                      ) : (
                        <img
                          src={require(`../../assets/img/black1.png`).default}
                          alt="nift"
                        />
                      )}
                      <select name="" id="">
                        <option value=""> Recently added </option>
                        <option value=""> Recently added-2 </option>
                        <option value=""> Recently added-3 </option>
                        <option value=""> Recently added-4 </option>
                        <option value=""> Recently added-5 </option>
                      </select>
                    </form>
                  </li>
                  <li>
                    <form className="digital-p-main-form" action="#">
                      {icon === "white" ? (
                        <img
                          src={
                            require(`../../assets/img/dollar-sign.png`).default
                          }
                          alt="nift"
                        />
                      ) : (
                        <img
                          src={require(`../../assets/img/black2.png`).default}
                          alt="nift"
                        />
                      )}

                      <select name="" id="">
                        <option value=""> Low Price </option>
                        <option value=""> Low Price-2 </option>
                        <option value=""> Low Price-3 </option>
                        <option value=""> Low Price-4 </option>
                        <option value=""> Low Price-5 </option>
                      </select>
                    </form>
                  </li>
                  <li>
                    <form className="digital-p-main-form" action="#">
                      {icon === "white" ? (
                        <img
                          src={
                            require(`../../assets/img/credit-card.png`).default
                          }
                          alt="nift"
                        />
                      ) : (
                        <img
                          src={require(`../../assets/img/black3.png`).default}
                          alt="nift"
                        />
                      )}

                      <select name="" id="">
                        <option value=""> Price Range </option>
                        <option value=""> Price Range-2 </option>
                        <option value=""> Price Range-3 </option>
                        <option value=""> Price Range-5 </option>
                        <option value=""> Price Range-6 </option>
                      </select>
                    </form>
                  </li>
                  <li>
                    <form className="digital-p-main-form" action="#">
                      {icon === "white" ? (
                        <img
                          src={
                            require(`../../assets/img/user-filter.png`).default
                          }
                          alt="nift"
                        />
                      ) : (
                        <img
                          src={require(`../../assets/img/black4.png`).default}
                          alt="nift"
                        />
                      )}

                      <select name="" id="">
                        <option value=""> Creators </option>
                        <option value=""> Creators-2 </option>
                        <option value=""> Creators-3 </option>
                        <option value=""> Creators-4 </option>
                        <option value=""> Creators-5 </option>
                      </select>
                    </form>
                  </li>
                  <li>
                    <form className="digital-p-main-form" action="#">
                      {icon === "white" ? (
                        <img
                          src={require(`../../assets/img/camera.png`).default}
                          alt="nift"
                        />
                      ) : (
                        <img
                          src={require(`../../assets/img/black5.png`).default}
                          alt="nift"
                        />
                      )}
                      <select name="" id="">
                        <option value=""> Photography </option>
                        <option value=""> Photography-2 </option>
                        <option value=""> Photography-3 </option>
                        <option value=""> Photography-4 </option>
                        <option value=""> Photography-5 </option>
                      </select>
                    </form>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-12">
            <div className={`row ${countDownStyle || ""}`}>
              {products &&
                products.length > 0 &&
                products.slice(0, 6).map((item) => (
                  <div
                    key={Math.random()}
                    className="col-xl-4 col-lg-4 col-md-6 mb-30"
                  >
                    <StyleTwo datas={item} cardStyleClass={cardStyleClass} />
                  </div>
                ))}
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="single-product-btn text-center">
                  <a className="btn" href="#">
                    View More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalProductSection;
