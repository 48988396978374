import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Blog from "./components/BlogPages/Blog/index";
import BlogDetails from "./components/BlogPages/BlogDetails/index";
import ConnectWallet from "./components/Community/ConnectWallet";
import ProfileSettings from "./components/Community/ProfileSettings/index";
import RouteScrollTop from "./components/Helpers/RouteScrollTop";
import HomeDark from "./components/HomeDark/index";
import HomeOne from "./components/HomeOne/index";
import HomeTwo from "./components/HomeTwo/index";
import DetailItem from "./components/Pages/DetailItem";
import AddLevels from "./components/ResourcePages/AddLevels";
import AddStats from "./components/ResourcePages/AddStatsCom";
import OfferSettingsCom from "./components/ResourcePages/OfferSettingsCom";
import OpenForBids from "./components/ResourcePages/OpenForBids/index";
import PlaceABidCom from "./components/ResourcePages/PlaceABidCom/index";
import PlaceBidThree from "./components/ResourcePages/PlaceBidThree";
import PlaceBidTwo from "./components/ResourcePages/PlaceBidTwo";
import Purchase from "./components/ResourcePages/Purchase";
import Activity from "./components/StatsPages/Activity";
import CollectionActivity from "./components/StatsPages/CollectionActivity";
import Profile from "./components/StatsPages/Profile";
import Ranking from "./components/StatsPages/Ranking";

export default function Routers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <RouteScrollTop>
      <Routes>
        {/* home */}
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/home-two" element={<HomeTwo />} />
        <Route exact path="/home-dark" element={<HomeDark />} />
        {/* stats */}
        <Route exact path="/status-activity" element={<Activity />} />
        <Route exact path="/status-ranking" element={<Ranking />} />
        <Route exact path="/my-profile" element={<Profile />} />
        <Route
          exact
          path="/collection-activity"
          element={<CollectionActivity />}
        />
        <Route exact path="/open-for-bids" element={<OpenForBids />} />
        <Route exact path="/add-levels" element={<AddLevels />} />
        <Route exact path="/offer-settings" element={<OfferSettingsCom />} />
        <Route exact path="/add-stats" element={<AddStats />} />
        <Route exact path="/place-a-bid" element={<PlaceABidCom />} />
        <Route exact path="/product-details" element={<PlaceABidCom />} />
        <Route exact path="/perchase-successfully" element={<Purchase />} />
        <Route exact path="/place-bid2" element={<PlaceBidTwo />} />
        <Route exact path="/place-bid3" element={<PlaceBidThree />} />
        <Route exact path="/connect-wallet" element={<ConnectWallet />} />
        <Route exact path="/detail-item" element={<DetailItem />} />
        <Route exact path="/profile-settings" element={<ProfileSettings />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog-details" element={<BlogDetails />} />
      </Routes>
    </RouteScrollTop>
  );
}
