import React, { useState } from "react";

function SettingsForBigScreen() {
  const settingItem = [
    {
      id: 1,
      name: "profile",
      title: "Profile",
      icon: "fa-solid fa-user",
    },
    {
      id: 2,
      name: "links",
      title: "Links",
      icon: "fa-solid fa-tag",
    },
    {
      id: 3,
      name: "notification",
      title: "Notification",
      icon: "fa-solid fa-bell",
    },
    {
      id: 4,
      name: "offers",
      title: "Offers",
      icon: "fa-solid fa-tag",
    },
    {
      id: 5,
      name: "appearence",
      title: "Appearence",
      icon: "fa-solid fa-cloud-sun-rain",
    },
    {
      id: 6,
      name: "account",
      title: "Account Support",
      icon: "fa-solid fa-circle-question",
    },
  ];
  const accountAccordion = [
    {
      id: 1,
      description:
        " Visit our help center to learn how to get started with buying, selling, and creating.",
      title: "General help",
    },
    {
      id: 2,
      description:
        " Visit our help center to learn how to get started with buying, selling, and creating.",
      title: "Contact OpenSea Support",
    },
    {
      id: 3,
      description:
        " Visit our help center to learn how to get started with buying, selling, and creating.",
      title: "Help with a compromised account",
    },
  ];
  const [tab, setTab] = useState(settingItem[0].name);
  const [accor, setAccor] = useState(accountAccordion[0].id);
  const tabHandler = (value) => setTab(value);
  const accorHandler = (value) => setAccor(value);
  return (
    <section className="user-profile-details-area pb-130 pt-200">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-md-12">
            <div className="user-settings-panel">
              <div className="d-flex align-items-start">
                <div
                  className="nav flex-column nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button type="button" className="tab-btn-design">
                    <i className="fa-solid fa-gear"></i>Settings
                  </button>
                  {settingItem &&
                    settingItem.length > 0 &&
                    settingItem.map((sItem) => (
                      <button
                        key={Math.random()}
                        onClick={() => tabHandler(sItem.name)}
                        className={`nav-link ${
                          sItem.name === tab ? "active" : ""
                        }`}
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        <i className={sItem.icon}></i> {sItem.title}
                      </button>
                    ))}
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      tab === "profile" ? "show active" : ""
                    }`}
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <ul className="clearfix profile-main-ul">
                      <li className="f-left w-50">
                        <a href="#">
                          <h3>Profile Settings</h3>
                        </a>
                      </li>
                      <li className="f-right w-50 text-end">
                        <a className="btn" href="#">
                          Preview <i className="fa-solid fa-eye"></i>
                        </a>
                      </li>
                    </ul>

                    <div className="prifile-area">
                      <div className="profile-setting-wrap">
                        <form action="#">
                          <div className="row">
                            <div className="col-xl-4">
                              <div className="profile-settng-left-form">
                                <div className="form-setting-input">
                                  <label> First Name </label>
                                  <input type="text" placeholder="First Name" />
                                </div>

                                <div className="form-setting-input">
                                  <label> Last Name </label>
                                  <input type="text" placeholder="Last name" />
                                </div>

                                <div className="form-setting-input">
                                  <label> Enter Username </label>
                                  <input
                                    type="text"
                                    placeholder="Enter username"
                                  />
                                </div>

                                <div className="form-setting-input">
                                  <label> Wallet address </label>
                                  <input
                                    type="text"
                                    placeholder="0x72e6663e20504b6153d4c5"
                                  />
                                </div>

                                <button type="submit">Save</button>
                              </div>
                            </div>
                            <div className="col-xl-8">
                              <div className="profile-setting-right-form">
                                <h3>Profile Image</h3>
                                <ul>
                                  <li>
                                    <a href="#">
                                      <img
                                        src={
                                          require(`../../../assets/img/profile-Bg.png`)
                                            .default
                                        }
                                        alt="nift"
                                      />
                                    </a>
                                  </li>
                                  <li>
                                    <a className="btn" href="#">
                                      <span>Upload New Picture</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a className="btn btn3" href="#">
                                      <span> Delete </span>
                                    </a>
                                  </li>
                                </ul>

                                <h3>Profile Image</h3>
                                <ul>
                                  <li>
                                    <a href="#">
                                      <img
                                        src={
                                          require(`../../../assets/img/profileBg2.png`)
                                            .default
                                        }
                                        alt="nift"
                                      />
                                    </a>
                                  </li>
                                  <li>
                                    <a className="btn" href="#">
                                      <span>Upload New Picture</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a className="btn btn3" href="#">
                                      <span> Delete </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${
                      tab === "links" ? "show active" : ""
                    }`}
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div className="social-wrap-main-wrapper">
                      <h3 className="mb-30">Social Links</h3>
                      <div className="links-main-wrapper">
                        <form action="#">
                          <div className="row">
                            <div className="col-xl-4 mb-30">
                              <div className="link-form">
                                <label> Facebook </label>
                                <input type="text" />
                              </div>
                            </div>
                            <div className="col-xl-4 mb-30">
                              <div className="link-form">
                                <label> Twitter </label>
                                <input type="text" />
                              </div>
                            </div>
                            <div className="col-xl-4 mb-30">
                              <div className="link-form">
                                <label> Pinterest </label>
                                <input type="text" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4">
                              <div className="link-form">
                                <label> Linkdein </label>
                                <input type="text" />
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <div className="link-form">
                                <label> Behance </label>
                                <input type="text" />
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <div className="link-form">
                                <label> Dribbble </label>
                                <input type="text" />
                              </div>
                            </div>
                          </div>
                        </form>

                        <div className="social-form-btn">
                          <button className="btn" type="submit">
                            Update Social Profiles
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab === "notification" ? "show active" : ""
                    }`}
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    <div className="notificatioin-wrapper">
                      <h3>Notifications Settings</h3>

                      <form action="#">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <div className="notification-setting-main-wrap">
                              <ul>
                                <li>
                                  <span>
                                    <input type="checkbox" />
                                  </span>
                                  <div className="notificatin-setting-text">
                                    <h4> Item Sold</h4>
                                    <p>
                                      When someone purchased one of your items
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <span>
                                    <input type="checkbox" />
                                  </span>
                                  <div className="notificatin-setting-text">
                                    <h4> Bid Activity </h4>
                                    <p>
                                      When someone purchased one of your items
                                    </p>
                                  </div>
                                </li>

                                <li>
                                  <span>
                                    <input type="checkbox" />
                                  </span>
                                  <div className="notificatin-setting-text">
                                    <h4> Price Change </h4>
                                    <p>
                                      When someone purchased one of your items
                                    </p>
                                  </div>
                                </li>

                                <li>
                                  <span>
                                    <input type="checkbox" />
                                  </span>
                                  <div className="notificatin-setting-text">
                                    <h4> Auction Expiration</h4>
                                    <p>
                                      When someone purchased one of your items
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <span>
                                    <input type="checkbox" />
                                  </span>
                                  <div className="notificatin-setting-text">
                                    <h4> Outbid </h4>
                                    <p>
                                      When someone purchased one of your items
                                    </p>
                                  </div>
                                </li>

                                <li>
                                  <span>
                                    <input type="checkbox" />
                                  </span>
                                  <div className="notificatin-setting-text">
                                    <h4> Referral Successful </h4>
                                    <p>
                                      When someone purchased one of your items
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${
                      tab === "offers" ? "show active" : ""
                    }`}
                    id="v-pills-Notifications"
                    role="tabpanel"
                    aria-labelledby="v-pills-Notifications-tab"
                  >
                    <h3 className="mb-30">Offer Settings</h3>
                    <div className="links-main-wrapper links-main-offers-wrapper text-center">
                      <div className="ofer-tab-inner-wrap">
                        <img src="img/Illustration.png" alt="nift" />

                        <h4>No collections to manage offers</h4>
                        <p>
                          You currently don’t have any collections and items to
                          manage offers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab === "appearence" ? "show active" : ""
                    }`}
                    id="v-pills-Offers"
                    role="tabpanel"
                    aria-labelledby="v-pills-Offers-tab"
                  >
                    <div className="apperience-settings">
                      <h3 className="mb-30">Appearance Settings</h3>

                      <div className="row">
                        <div className="col-xl-12">
                          <div className="apperience-wrapper">
                            <h4>Theme</h4>
                            <ul>
                              <li>
                                <button type="button" className="btn">
                                  <img
                                    src={
                                      require(`../../../assets/img/sun.png`)
                                        .default
                                    }
                                    alt="nift"
                                  />
                                  Light mode
                                </button>
                              </li>
                              <li>
                                <button type="button" className="btn btn5">
                                  <img src="img/Vector.png" alt="nift" /> Dark
                                  mode
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab === "account" ? "show active" : ""
                    }`}
                    id="v-pills-Appearence"
                    role="tabpanel"
                    aria-labelledby="v-pills-Appearence-tab"
                  >
                    <div className="account-support-area">
                      <h3>Account Support</h3>

                      <div className="account-support-main-wrap">
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="account-support-accordion-wrap">
                              <div className="accordion" id="accordionExample">
                                {accountAccordion &&
                                  accountAccordion.length > 0 &&
                                  accountAccordion.map((aItem) => (
                                    <div
                                      key={Math.random()}
                                      className="accordion-item"
                                    >
                                      <h2
                                        className="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          onClick={() => accorHandler(aItem.id)}
                                          className={`accordion-button ${
                                            accor === aItem.id
                                              ? ""
                                              : "collapsed"
                                          }`}
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          <h2>{aItem.title}</h2>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        className={`accordion-collapse  collapse ${
                                          accor === aItem.id ? "show" : ""
                                        }`}
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          <p>{aItem.description}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SettingsForBigScreen;
