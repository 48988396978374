import React from "react";

function AddLevel({ action, value }) {
  return (
    <>
      <section
        id="wrapperModal"
        className="modal-area modal"
        style={{ display: value ? "flex" : "none" }}
      >
        <div
          onClick={action}
          className={`single-profile-away ${value ? "active" : ""}`}
        ></div>
        <div className="modal-single-wrap">
          <div className="closePopup" onClick={action}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="modal-title">
            <h3> Add Levels </h3>
            <p>
              Levels show up underneath your item, are clickable, and can be
              filtered in your collection's sidebar.
            </p>

            <form action="#">
              <div className="row">
                <div className="col-xl-6 p-0 col-lg-6 col-md-6">
                  <div className="modal-input-wrap">
                    <label> Name </label>
                    <i className="fa-solid fa-xmark"></i>
                    <input type="text" placeholder="Rakib Khan" />
                  </div>

                  <div className="modal-input-wrap">
                    <i className="fa-solid fa-xmark"></i>
                    <input type="text" placeholder="Rakib Khan" />
                  </div>
                  <div className="modal-btn">
                    <a className="btn" href="#">
                      Add more
                    </a>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="add-label-right-box">
                    <div className="modal-input-wrap">
                      <label> Value </label>
                      <ul>
                        <li className="add-input1">
                          <input type="number" placeholder="3" />
                        </li>
                        <li className="add-input2">of</li>
                        <li className="add-input3">
                          <input type="number" placeholder="3" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="add-label-right-box">
                    <div className="modal-input-wrap">
                      <ul>
                        <li className="add-input1">
                          <input type="number" placeholder="3" />
                        </li>
                        <li className="add-input2">of</li>
                        <li className="add-input3">
                          <input type="number" placeholder="3" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="popup-bottom-title text-center">
                  <a className="btn" href="#">
                    Save
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddLevel;
