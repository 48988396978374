import React from "react";
import SelectBox from "../../Helpers/SelectBox";

function FooterOne({ className }) {
  const language = ["English", "Bangla", "Hinde"];
  return (
    <footer className={`footer-area pt-120 pb-40 ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
            <div className="footer-widget footer-widget1">
              <h3> Get the latest NFT updates </h3>
              <form action="#">
                <input type="email" placeholder="Your e-mail" />
                <button type="submit"> I’m in </button>
              </form>
            </div>
          </div>

          <div className="col-xl-2 col-lg-6 col-md-12 mb-30">
            <div className="footer-widget footer-widget2">
              <h3>NFT </h3>
              <ul>
                <li>
                  <a href="#"> Explore </a>
                </li>
                <li>
                  <a href="#"> Help center </a>
                </li>
                <li>
                  <a href="#"> Blog </a>
                </li>
                <li>
                  <a href="#"> Jobs </a>
                </li>
                <li>
                  <a href="#"> Become a partner </a>
                </li>
                <li>
                  <a href="#"> Bug bounty </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-2 col-lg-6 col-md-12 mb-30">
            <div className="footer-widget footer-widget3">
              <h3> Community </h3>
              <ul>
                <li>
                  <a href="#"> ARAI Token </a>
                </li>
                <li>
                  <a href="#"> Discussion </a>
                </li>
                <li>
                  <a href="#"> Voting </a>
                </li>
                <li>
                  <a href="#"> Suggest feature </a>
                </li>
                <li>
                  <a href="#">NFT protocol </a>
                </li>
                <li>
                  <a href="#">Subscribe </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-4 col-lg-6 col-md-12 mb-30">
            <div className="footer-widget footer-widget4">
              <h3> Language </h3>
              <SelectBox datas={language} className="language-select-box" />
            </div>
          </div>
        </div>

        <div className="row mt-105 footer-border">
          <div className="col-xl-6 col-lg-7 col-md-12">
            <div className="footer-bottom-left">
              <ul>
                <li>
                  <a href="#"> © NFT, Inc. All rights reserved. </a>
                </li>
                <li>
                  <a href="#"> Terms </a>
                </li>
                <li>
                  <a href="#"> Privacy policy </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6 col-lg-5 col-md-12">
            <div className="footer-bottom-right">
              <ul>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterOne;
