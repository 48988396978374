import { useState } from "react";

function useToggle(init = false) {
  const [value, setValue] = useState(init);
  return [
    value,
    {
      toggle: () => {
        setValue((flag) => !flag);
      },
    },
  ];
}
export default useToggle;
