import React from "react";
import avatar from "../../assets/img/home-01/avatar.png";

function Hero() {
  return (
    <>
      <section className="hero-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="hero-left-content">
                <h2>Explore, Collect, and Sell NFTs</h2>
                <p>On the world's Best & largest NFT marketplace</p>
                <div className="hero-btn">
                  <a href="#" className="btn">
                    Explore
                  </a>
                  <a href="#" className="btn hero-sec-btn">
                    Create
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 con-md-6">
              <div className="row mt-50">
                <div className="col-xl-6 col-lg-6 col-md-6 mb-30">
                  <div className="hero-right-area">
                    <div className="hero-right-img">
                      <a href="#">
                        <img
                          src={
                            require(`../../assets/img/home-01/10.jpg`).default
                          }
                          alt="nift"
                        />
                      </a>
                    </div>
                    <div className="single-hero-meta">
                      <img src={avatar} alt="nift" />
                      <div className="hero-meta-text">
                        <a href="#">
                          <h4>User centered</h4>
                        </a>
                        <span>$180,583</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="hero-right-area">
                    <div className="hero-right-img">
                      <a href="#">
                        <img
                          src={
                            require(`../../assets/img/home-01/11.png`).default
                          }
                          alt="nift"
                        />
                      </a>
                    </div>
                    <div className="single-hero-meta">
                      <a href="#">
                        <img
                          src={
                            require(`../../assets/img/home-01/Image-6.png`)
                              .default
                          }
                          alt="nift"
                        />
                      </a>
                      <div className="hero-meta-text">
                        <a href="#">
                          <h4>User centered</h4>
                        </a>
                        <span>$180,583</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
