import React from "react";

function SuccessModal({ action, value, className }) {
  return (
    <>
      <section
        id="wrapperModal"
        style={{ display: value ? "flex" : "none" }}
        className={`modal-area modal modal-area2 product-details-popup ${
          className || ""
        }`}
      >
        <div
          onClick={action}
          className={`single-profile-away ${value ? "active" : ""}`}
        ></div>
        <div className="modal-single-wrap">
          <div className="closePopup" onClick={action}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="purcase-succes-wrap">
            <h2>Yay!</h2>
            <p>
              You successfully purchase tutut <br />
              from Jpnathon
            </p>

            <div className="perchase-box-body">
              <ul className="clearfix">
                <li className="f-left w-50 text-start">
                  <a href="#"> Status </a>
                </li>
                <li className="f-left w-50 text-end">
                  <a href="#"> Transaction Id </a>
                </li>
              </ul>

              <ul className="clearfix">
                <li className="f-left w-50 text-start">
                  <a href="#"> Status </a>
                </li>
                <li className="f-left w-50 text-end">
                  <a href="#"> Transaction Id </a>
                </li>
              </ul>
            </div>

            <div className="purchase-box-footer">
              <p>Time to show-off</p>
              <ul>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SuccessModal;
