import React from "react";

function Hero() {
  return (
    <section className="section hero3-section">
      <div className="container position-relative">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="hero3-content text-center">
              <h2>
                Explore, Collect, and <br /> Sell NFTs
              </h2>
              <p>On the world's Best & largest NFT marketplace</p>
              <a className="btn" href="#">
                Explore
              </a>
            </div>
          </div>
        </div>

        <div className="nft-heading">
          <h2>NFT</h2>
        </div>

        <div className="home3-shape1 text-class3">
          <div className="home3-shap-img">
            <img
              src={require(`../../assets/img/03_Home/Bg-10.png`).default}
              alt="nift"
            />
          </div>
          <span>Current</span>
          <h3>219 ETH</h3>
          <a href="#">Place a bid</a>
        </div>

        <div className="home3-shape2 text-class3">
          <div className="home3-shap-img">
            <img
              src={require(`../../assets/img/03_Home/Bg-6.png`).default}
              alt="nift"
            />
          </div>
          <span>Current</span>
          <h3>10 ETH</h3>
          <a href="#">Place a bid</a>
        </div>

        <div className="home3-shape3 text-class3">
          <div className="home3-shap-img">
            <img
              src={require(`../../assets/img/03_Home/Bg-8.png`).default}
              alt="nift"
            />
          </div>
          <span>Current</span>
          <h3>21 ETH</h3>
          <a href="#">Place a bid</a>
        </div>

        <div className="home3-right1 text-class3 right-same-shape">
          <div className="home3-shap-img">
            <img
              src={require(`../../assets/img/03_Home/Bg-7.png`).default}
              alt="nift"
            />
          </div>
          <span>Current</span>
          <h3>29 ETH</h3>
          <a href="#">Place a bid</a>
        </div>

        <div className="home3-right2 text-class3 right-same-shape">
          <div className="home3-shap-img">
            <img
              src={require(`../../assets/img/03_Home/Bg-9.png`).default}
              alt="nift"
            />
          </div>
          <span>Current</span>
          <h3>19 ETH</h3>
          <a href="#">Place a bid</a>
        </div>

        <div className="home3-right3 text-class3 right-same-shape">
          <div className="home3-shap-img">
            <img
              src={require(`../../assets/img/03_Home/Bg-5.png`).default}
              alt="nift"
            />
          </div>
          <span>Current</span>
          <h3>9 ETH</h3>
          <a href="#">Place a bid</a>
        </div>
      </div>
    </section>
  );
}

export default Hero;
