import React from "react";

function FilterSection() {
  return (
    <div className="shop-left-main-filter">
      <ul>
        <li className=" filter-fst-wrap clearfix">
          <div className="filder-left f-left w-50">
            <a href="#">
              <i className="fa-solid fa-filter"></i> <span>Filter</span>
            </a>
          </div>
          <div className="filder-arrow-right f-right text-right w-50">
            <a href="#">
              <i className="fa-solid fa-arrow-left"></i>
            </a>
          </div>
        </li>

        <li className="filter2-wrap">
          <div className="filter-top-arrow-main-wrap"></div>

          <ul className="clearfix statur-title">
            <li className="f-left w-50">
              <a href="#"> Status </a>
            </li>
            <li className="f-right w-50 text-right startu-icon-right">
              <a href="#">
                <i className="fa-solid fa-chevron-up"></i>
              </a>
            </li>
          </ul>

          <div className="filter-btn-main-wrap clearfix">
            <div className="filter-btn-left f-left w-50">
              <a className="btn  active-btn" href="#">
                New
              </a>
            </div>

            <div className="filter-btn-right f-left w-50">
              <a className="btn  f-default-btn" href="#">
                On Auction
              </a>
            </div>
          </div>
        </li>

        <li className="filter2-wrap price-field">
          <div className="filter-top-arrow-main-wrap"></div>

          <ul className="clearfix statur-title">
            <li className="f-left w-50">
              <a href="#"> All Items </a>
            </li>
            <li className="f-right w-50 text-right startu-icon-right">
              <a href="#">
                <i className="fa-solid fa-chevron-up"></i>
              </a>
            </li>
          </ul>

          <div className="filter-btn-main-wrap clearfix">
            <div className="filter-btn-left f-left w-50">
              <a className="btn  active-btn single-btn-design" href="#">
                Single
              </a>
            </div>

            <div className="filter-btn-right f-left w-50">
              <a className="btn  f-default-btn" href="#">
                Bundle
              </a>
            </div>
          </div>
        </li>

        <li className="price-filter clearfix">
          <div className="price-title-wrap clearfix">
            <div className="price-main-wrapper clearfix">
              <div className="price-title-left f-left w-50">
                <a href="#">
                  <h3>Price</h3>
                </a>
              </div>
              <div className="price-right f-right w-50 text-right">
                <a href="#">
                  <i className="fa-solid fa-chevron-up"></i>
                </a>
              </div>
            </div>

            <div className="usd-text-wrapper">
              <h3>$USD</h3>
            </div>

            <div className="price-filter-form">
              <form action="#">
                <input type="text" placeholder="Min" />
                <input type="text" placeholder="Max" />
                <button type="submit"> Apply </button>
              </form>
            </div>
          </div>
        </li>

        <li>
          <div className="collection-main-wrapper">
            <div className="collection-header clearfix bg-white">
              <ul>
                <li className="f-left">
                  <a href="#">
                    <h3>Collections</h3>
                  </a>
                </li>
                <li className="f-right">
                  <a href="#">
                    <i className="fa-solid fa-angle-up"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="collection-body">
              <form action="#">
                <i>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </i>
                <input type="search" placeholder="Search" />
              </form>
              <ul>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-3.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span>Leslie Alexander</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-1.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span>Guy Hawkins</span>
                  </a>
                </li>
              </ul>

              <ul>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span>Theresa Webb </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-6.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span>Wade Warren </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-7.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span> Devon Lane </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li>
          <div className="collection-main-wrapper">
            <div className="collection-header clearfix bg-white">
              <ul>
                <li className="f-left">
                  <a href="#">
                    <h3>Categories</h3>
                  </a>
                </li>
                <li className="f-right">
                  <a href="#">
                    <i className="fa-solid fa-angle-up"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="collection-body">
              <ul>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-3.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span> Illustrations </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-1.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span> Art </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span> Domain Names </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-6.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span> Trading Cards </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-7.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span> Collectibles </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li>
          <div className="collection-main-wrapper">
            <div className="collection-header clearfix bg-white">
              <ul>
                <li className="f-left">
                  <a href="#">
                    <h3>Categories</h3>
                  </a>
                </li>
                <li className="f-right">
                  <a href="#">
                    <i className="fa-solid fa-angle-up"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="collection-body">
              <ul>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-3.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span>Leslie Alexander</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image-1.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span>Leslie Alexander</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../../assets/img/21_Stats-Activity/Image.png`)
                          .default
                      }
                      alt="nift"
                    />
                    <span>Leslie Alexander</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default FilterSection;
