import React from "react";

function MainContent() {
  return (
    <>
      <section className="detail-item-area pt-160 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="detail-item-top-btn">
                <a className="btn" href="#">
                  View More
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
          </div>

          <div className="row text-center">
            <div className="col-xl-12 col-lg-12 col-sm-12">
              <div className="detail-tem-title">
                <h2>Create new item</h2>
                <p>
                  Image, Video, Audio, or 3D Model. File types supported: JPG,
                  PNG, GIF, SVG, MP4, <br /> WEBM, MP3, WAV, OGG, GLB, GLTF. Max
                  size: 100 MB
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="detail-single-item-wrap detail-single-item-wrap-left">
                <div className="detail-single-i-btn">
                  <a className="btn" href="#">
                    Create single item
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="detail-single-item-wrap detail-single-item-wrap-right">
                <div className="detail-single-i-btn">
                  <a className="btn" href="#">
                    Create single item
                  </a>
                </div>
              </div>
            </div>

            <div className="details-single-item-text mt-50">
              <p>
                We do not own your private keys and cannot access your <br />
                funds without your confirmation
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainContent;
