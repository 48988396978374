import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("home");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      setSize(`${43 * getItems}px`);
      if (item !== value) {
        setItem(value);
      } else {
        setItem("");
      }
    }
  };
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <div className="canvas_close">
                  <a href="#" onClick={(e) => action(e)}>
                    <i className="fa fa-times"></i>
                  </a>
                </div>
                <div className="offcanvas-brand text-center mb-40">
                  <img src="" alt="nift" />
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li
                      onClick={(e) => handler(e, "home")}
                      id="home"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a href="#">Home</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "home" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/">Home 1</Link>
                        </li>
                        <li>
                          <Link to="/home-two">Home 2</Link>
                        </li>
                        <li>
                          <Link to="/home-dark">Home 3</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "explore")}
                      id="explore"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a href="#">Explore</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "explore" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/status-activity">Status Activity</Link>
                        </li>
                        <li>
                          <Link to="/status-ranking">Status Ranking</Link>
                        </li>
                        <li>
                          <Link to="/my-profile"> My Profile </Link>
                        </li>
                        <li>
                          <Link to="/collection-activity">
                            Collecton Activity
                          </Link>
                        </li>
                        <li>
                          <Link to="/status-activity">Status Activity</Link>
                        </li>
                        <li>
                          <Link to="/open-for-bids">Open For Bids</Link>
                        </li>
                        <li>
                          <Link to="/place-a-bid">Place A Bid</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "pages")}
                      id="pages"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a href="#">Pages</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "pages" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/offer-settings">Offer Setting</Link>
                        </li>
                        <li>
                          <Link to="/perchase-successfully">Perchase</Link>
                        </li>
                        <li>
                          <Link to="/place-bid2">Place A Bid 2</Link>
                        </li>
                        <li>
                          <Link to="/place-bid3">Place A Bid 3</Link>
                        </li>
                        <li>
                          <Link to="/my-profile"> My Profile </Link>
                        </li>
                        <li>
                          <Link to="/collection-activity">
                            Collectoin Activity
                          </Link>
                        </li>
                        <li>
                          <Link to="/add-levels"> Add Levels </Link>
                        </li>
                        <li>
                          <Link to="/add-stats"> Add Stats </Link>
                        </li>

                        <li>
                          <Link to="/connect-wallet">Connect Wallet</Link>
                        </li>
                        <li>
                          <Link to="/profile-settings">Profile Setting</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "blog")}
                      id="blog"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a href="#">News</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "blog" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/blog"> Blog </Link>
                        </li>
                        <li>
                          <Link to="/blog-details"> Blog Details </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a href="$">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="$">
                        <i className="fab fa-dribbble"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget-info">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fal fa-envelope"></i> support@appie.com
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-phone"></i> +(642) 342 762 44
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-map-marker-alt"></i> 442 Belle
                        Terre St Floor 7, San Francisco, AV 4206
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
