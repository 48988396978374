import React from "react";

function UserDetails() {
  return (
    <>
      <section className="detail-profile-top-bg mt-100"></section>
      <section className="detail-profile-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-2">
              <div className="details-left">
                <a href="#">
                  <i className="fa-solid fa-globe"></i>
                </a>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="detail-profile-wrap detail-profile-wrap2">
                <img
                  className="profile-picture"
                  src={
                    require(`../../../assets/img/24_MyProfile/Profile_Image.png`)
                      .default
                  }
                  alt="nift"
                />
                <h2>Jonathon Doe</h2>
                <h4>
                  0xc4c16a125..b21a <i className="fa-solid fa-copy"></i>
                </h4>

                <p>
                  Pavel Sokov is a renowned portrait painter. When he was 24
                  years old, Sokov was approached by Time to paint the cover of
                  the Time's.... <a href="#">Read More</a>
                </p>

                <ul className="my-profle-follow-count">
                  <li>
                    <a href="#">
                      <span>513</span> followers
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>213</span> following
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="detail-right-bar">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa-solid fa-arrow-up-from-bracket"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa-solid fa-gear"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserDetails;
