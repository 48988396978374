import React from "react";

function CheckoutModal({ action, value, className }) {
  return (
    <>
      <section
        id="wrapperModal"
        style={{ display: value ? "flex" : "none" }}
        className={`modal-area modal ${className || ""}`}
      >
        <div
          onClick={action}
          className={`single-profile-away ${value ? "active" : ""}`}
        ></div>
        <div className="modal-single-wrap">
          <div className="closePopup" onClick={action}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="modal-title">
            <h3> Checkout </h3>
            <p> You are about to purchase tutut from Jpnathon </p>

            <form action="#">
              <div className="row">
                <div className="shop-detail-popup-wrap">
                  <h4>Price </h4>
                  <div className="shop-popup-left">
                    <button type="button">
                      <img
                        src={
                          require(`../../../assets/img/ethereum 2.png`).default
                        }
                        alt="nift"
                      />
                      ETH
                    </button>
                  </div>
                  <div className="shop-popup-middle">
                    <input type="text" placeholder="110.566" />
                  </div>
                  <div className="shop-popup-right">
                    <button type="submit">$12,255,123.00</button>
                  </div>
                </div>

                <div className="shop-popup-body3">
                  <ul className="clearfix place-bid3-ul1">
                    <li className="f-left"> Your balance </li>
                    <li className="f-right text-right"> 8.555 ETH </li>
                  </ul>

                  <ul className="clearfix place-bid3-ul1">
                    <li className="f-left"> Service fee </li>
                    <li className="f-right text-right"> 0 ETH </li>
                  </ul>

                  <ul className="clearfix place-bid3-ul1">
                    <li className="f-left"> Yow will pay </li>
                    <li className="f-right text-right"> 0 ETH </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
          <div className=" popup-bottom-title popup2-bottom-btn text-center">
            <ul className="place-bid3-ul2">
              <li>
                <a className="place-bid3-ula-active" href="#">
                  I understand, continue
                </a>
              </li>
              <li>
                <a href="#"> Cancel </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default CheckoutModal;
