import React from "react";
import { collectionUser } from "../../data/collectionUsersTwo.json";
import { process } from "../../data/process.json";
import { products } from "../../data/productHomeTwo.json";
import { reviews } from "../../data/reviews.json";
import useToggle from "../../hooks/useToggle";
import GoToTop from "../Helpers/GoToTop";
import LearnSection from "../HomeOne/LearnSection";
import LiveAuctions from "../HomeOne/LiveAuctions";
import ProcessArea from "../HomeOne/ProcessArea";
import Sponser from "../HomeOne/Sponser";
import TestimonialSection from "../HomeOne/TestimonialSection";
import Drawer from "../Mobile/Drawer";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderTwo from "../Partials/Headers/HeaderTwo";
import DigitalProductSection from "./DigitalProductSection";
import Hero from "./Hero";
import TopSelectionSection from "./TopSelectionSection";

function HomeTwo() {
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={setDrawer.toggle} />
      <HeaderTwo action={setDrawer.toggle} />
      <Hero />
      <DigitalProductSection
        countDownStyle="home3-cowndown"
        products={products}
        className="digital-product-area-3"
        cardStyleClass="single-product-h3"
      />
      <TopSelectionSection
        collectionUser={collectionUser}
        className="top-collectoin-area-h3"
      />
      <ProcessArea className="process-area-h3" processDatas={process} />
      <LiveAuctions
        datas={products}
        cardStyle={2}
        className="quomodo-r-live-auction live-auction-h3 single-product-h3 home3-cowndown"
      />
      <TestimonialSection
        reviews={reviews}
        className="home2-testimonial-area home3-testimonial pt-0 pb-0"
        reviewCardStyle="home3-testimonia-bg"
      />
      <Sponser />
      <LearnSection />
      <FooterOne className="footer2-area footer3-area" />
      <GoToTop />
    </>
  );
}

export default HomeTwo;
