import React from "react";

function Hero() {
  return (
    <section className="hero-area2 pt-210 pb-130 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="hero-title2 text-center">
              <h2>
                Explore, Create, <br />
                and Sell Your Rare arts
              </h2>
              <p>On the world's Best & largest NFT marketplace</p>
              <div className="hero-btn2">
                <a className="btn2" href="#">
                  Explore
                </a>
                <a className="btn2 hero2-yellow-btn" href="#">
                  Explore
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center pt-100">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <div className="hero-bottom-img2">
              <img
                src={require(`../../assets/img/02_Home/Image-5.png`).default}
                alt="nift"
              />
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <div className="hero-bottom-img2">
              <img
                src={require(`../../assets/img/02_Home/Image-6.png`).default}
                alt="nift"
              />
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <div className="hero-bottom-img2">
              <img
                className="satisfied"
                src={
                  require(`../../assets/img/1000+ Satisfied Clients.png`)
                    .default
                }
                alt="nift"
              />
              <img
                src={require(`../../assets/img/02_Home/Image-7.png`).default}
                alt="nift"
              />
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <div className="hero-bottom-img2">
              <img
                src={require(`../../assets/img/02_Home/Image-8.png`).default}
                alt="nift"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="hero2-shape1">
        <img
          src={require(`../../assets/img/home2-vector4.png`).default}
          alt="nift"
        />
      </div>
      <div className="hero2-shape2">
        <img
          src={require(`../../assets/img/hme2Vector-1.png`).default}
          alt="nift"
        />
      </div>
      <div className="hero2-shape3">
        <img
          src={require(`../../assets/img/home2-Vector2.png`).default}
          alt="nift"
        />
      </div>
    </section>
  );
}

export default Hero;
