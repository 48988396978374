import React from "react";
import { Link } from "react-router-dom";

function StyleOne({ datas, className, dataIndex, evenly = false }) {
  return (
    <div className={`artist-single-carousel ${className || ""}`}>
      <div className="carousel-img">
        <img
          src={require(`../../assets/img/home-01/${datas.thumbnail}`).default}
          alt="nift"
        />
        <a
          href="#"
          className={`carousel-shap-btn ${
            evenly && dataIndex % 2 === 0 ? "explore-blue-btn" : ""
          }`}
        >
          {datas.category}
        </a>
      </div>

      <div className="artist-single-text-wrap clearfix">
        <div className="user-artist f-left">
          <img
            src={
              require(`../../assets/img/24_MyProfile/${datas.user_image}`)
                .default
            }
            alt="nift"
          />
          <div className="user-artits-name">
            <a href="#"> {datas.username} </a>
          </div>
        </div>

        <div className="user-artist-right-text f-right">
          <h5>
            {datas.reminder}
            <span>Left</span>
          </h5>
        </div>
      </div>

      <div className="user-artitst-meta">
        <Link to="/product-details">
          <h3 className="line-clump-1 text-capitalize"> {datas.title} </h3>
        </Link>
        <h4 className="line-clump-1">{datas.description}</h4>
        <ul className="clearfix">
          <li className="f-left">
            <span>{datas.price}</span>
          </li>
          <li className="f-right">
            <a href="#">
              <i className="fa-solid fa-heart"></i>
              <h5>{datas.review}</h5>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default StyleOne;
