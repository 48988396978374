import React from "react";

function FollowModal({ action, value, className }) {
  return (
    <>
      <section
        id="wrapperModal"
        style={{ display: value ? "flex" : "none" }}
        className={`modal-area modal ${className || ""}`}
      >
        <div
          onClick={action}
          className={`single-profile-away ${value ? "active" : ""}`}
        ></div>
        <div className="modal-single-wrap">
          <div className="closePopup" onClick={action}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="modal-title">
            <h3> Follow Steps </h3>

            <form action="#">
              <div className="row">
                <ul className="clearfix">
                  <li className="f-left ">
                    <a className="place-bid-a place-bid-a-active" href="#">
                      <i className="fa-solid fa-check"></i>
                    </a>
                    <h4>Deposit</h4>
                    <p>Send transaction with your wallet</p>
                    <div className="place-bid2-btn">
                      <a className="place-bid-a2" href="#">
                        Start Now
                      </a>
                    </div>
                  </li>

                  <li className="f-left">
                    <a className="place-bid-a" href="#">
                      <i className="fa-solid fa-check"></i>
                    </a>
                    <h4>Approve</h4>
                    <p>Send transaction with your wallet</p>
                    <div className="place-bid2-btn">
                      <a className="place-bid-a2 place-bid-a2-active" href="#">
                        Start Now
                      </a>
                    </div>
                  </li>

                  <li className="f-left">
                    <a className="place-bid-a" href="#">
                      <i className="fa-solid fa-pen-nib"></i>
                    </a>
                    <h4>Signature</h4>
                    <p>Send transaction with your wallet</p>
                    <div className="place-bid2-btn">
                      <a className="place-bid-a2" href="#">
                        Start Now
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default FollowModal;
