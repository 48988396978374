import React from "react";

function ReviewCardStyleOne({ className, datas }) {
  return (
    <div className={`single-testimonial ${className || ""}`}>
      <ul className="clearfix">
        <li className="f-left testimonial-left-quite">
          <i className="fa-solid fa-quote-left"></i>
        </li>
        <li className="f-right testmonial-right-star">
          <ul className="testimonail-inner-start">
            <li>
              <i className="fa-solid fa-star"></i>
            </li>
            <li>
              <i className="fa-solid fa-star"></i>
            </li>
            <li>
              <i className="fa-solid fa-star"></i>
            </li>
            <li>
              <i className="fa-solid fa-star"></i>
            </li>
            <li>
              <i className="fa-solid fa-star"></i>
            </li>
          </ul>
        </li>
      </ul>

      <p className="line-clump-5">{datas.comments}</p>

      <div className="testimonial-meta">
        <img
          src={require(`../../assets/img/home-01/${datas.image}`).default}
          alt="nift"
        />
        <div className="testimonial-meta-content">
          <h3 className="line-clump-1">{datas.username}</h3>
          <h4 className="line-clump-1">{datas.designation}</h4>
        </div>
      </div>
    </div>
  );
}

export default ReviewCardStyleOne;
