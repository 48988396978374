import { useEffect, useState } from "react";
import Preloader from "./components/Helpers/PreLoader";
import Routers from "./Routers";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.style.overflowY = "scroll";
    }, 2000);
  });
  return (
    <>
      {loading && <Preloader />}
      <div className={`nft ${loading === false ? "active" : ""}`}>
        <Routers />
      </div>
    </>
  );
}

export default App;
